import { Component, OnInit, Input } from '@angular/core';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { ApplicationService } from 'src/shared/application.service';
import { ISession } from 'src/model/service/isession';
import { ICapacity } from 'src/model/service/icapacity';
import { ILocation } from 'src/model/service/ilocation';
import { IDateDetails } from 'src/model/application/idatedetails';
import { Router } from '@angular/router';
import { IOfferDetails } from 'src/model/application/iofferdetails';
import { IServerResponse } from 'src/model/service/serverresponse';
import { IEvent } from 'src/model/service/ievent';
import { IFieldValue } from 'src/model/service/ifieldvalue';
import { IFieldDetails } from 'src/model/application/ifielddetails';

@Component({
  selector: 'app-eventbookingmodal',
  templateUrl: './eventbookingmodal.component.html',
  styleUrls: ['./eventbookingmodal.component.css']
})
export class EventbookingmodalComponent implements OnInit {

  @Input() suitableEvents: IMonthEvent[];
  selectedEvent: IMonthEvent;
  session: ISession;
  capacity: ICapacity;
  location: ILocation;
  offer: IOfferDetails;
  waitingForBooking = false;
  waitingForEventCreation = false;
  hasSuitableHomeEvent = false;

  constructor(private appdata: ApplicationService, private router: Router) {
    appdata.isLoading().subscribe((state: boolean) => {
      if (!state) {
        this.session = appdata.getData().session;
        this.capacity = appdata.getCapacityBId(this.session.user);
        this.location = appdata.getLocationById(this.capacity.homelocation);
      }
    })

    appdata.getAcceptedOffer().subscribe((offer: IOfferDetails) => {
      console.log("getAcceptedOffer():" + JSON.stringify(offer))
      if (offer != null) {
        this.offer = offer;
        this.suitableEvents = this.appdata.getEventsByOffer(this.offer);
        let capacity = appdata.getCapacityBId(this.session.user);
        this.hasSuitableHomeEvent = false;

        this.suitableEvents.forEach((e:IMonthEvent)=>{
          if(e.location.id === capacity.homelocation && !this.hasSuitableHomeEvent){
            this.hasSuitableHomeEvent = true;
          }
        })
        
        if (this.waitingForBooking) {        
          this.waitingForBooking = false
        }
        if (this.waitingForEventCreation) {
          this.waitingForEventCreation = false;
        }
      }
    })

  }

  public waitForBooking() {
    this.waitingForBooking = true;
    this.book();
  }
  public waitForEventCreation() {
    this.waitingForEventCreation = true;
    this.createEvent();
  }

  public book() {
    if (this.selectedEvent != null) {
      if (confirm(this.offer.capacity.firstname + " " + this.offer.capacity.lastname + " jetzt der Veranstaltung hinzufügen?")) {
        this.selectedEvent.lead_capacity = this.offer.capacity;

        //Falls es ein Gottesdienst ist: Setze Anbieter in Feld Predigt:
        if(this.selectedEvent.type.id === 1){
          this.selectedEvent.fieldValues.forEach((field:IFieldDetails)=>{
            if(field.id === 5){
              field.value = this.offer.capacity.id;
              field.valueResolved = this.offer.capacity.id;
            }
          })
        }

        this.selectedEvent.relatedOffer = {
          id: this.offer.id,
          date: this.offer.date.date,
          create_capacity_id: this.offer.capacity.id,
          location_id: this.offer.location.id
        } 

        this.appdata.updateEvent(this.selectedEvent).subscribe((data) => {
          let response: IServerResponse<IEvent> = JSON.parse(JSON.stringify(data)); // :)
          if (response.operationState) {
            //this.router.navigate(["/home"]);
            location.href = "/home";
          } else {
            alert(response.message);
          }
        })
      }
    } else {
      alert("Bitte wähle zunächst einen Termin aus!");
    }
  }

  public createEvent() {
    if (confirm("Das Datum " + this.offer.date.daynameFull + ", " + this.offer.date.day + ". " + this.offer.date.monthnameFull + " ist in Deiner Gemeinde noch nicht belegt.\nNeue Veranstaltung jetzt erstellen?")) {

      let location = this.offer.location;
      if (this.offer.location != null) {
        location = this.offer.location;
      } else {
        let capacity = this.appdata.getSessionCapayity();
        location = this.appdata.getLocationById(capacity.homelocation);
      }

      this.appdata.createNewEvent(this.offer.date, location, this.offer);
      this.router.navigate(["/edit/new/"]);
    }
  }

  ngOnInit() {
  }

}
