import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationService } from 'src/shared/application.service';
import { IOfferDetails } from 'src/model/application/iofferdetails';
import { ILocation } from 'src/model/service/ilocation';
import { DatepickerOptions } from 'ng2-datepicker';
import { DatetimeService } from 'src/shared/datetime.service';
import { IOffer } from 'src/model/service/ioffer';
import { IMonthEvent } from 'src/model/application/imonthevent';

declare var $: any;

@Component({
  selector: 'app-transactions',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.css']
})
export class TransactionsComponent implements OnInit {

  title = "Angebote";
  offers: IOfferDetails[] = [];
  locations: ILocation[] = [];
  selectedLocation: ILocation;
  selectedDate: Date = new Date();
  selectedOffer: IOfferDetails = null;
  options: DatepickerOptions = {};
  alertmessage: string;
  showAlert: boolean = false;
  suitableEvents: IMonthEvent[];

  constructor(private appdata: ApplicationService, private dateservice: DatetimeService) {
    this.options = dateservice.getDatepickerOptions();
    this.appdata.isLoading().subscribe((state: boolean) => {

      if (!state) {

        this.offers = appdata.getMyOffers();
        let locations = appdata.getLocations();
        let sessionCapacity = appdata.getSessionCapayity();   
        let sessionLocationId = sessionCapacity.homelocation;
        let homeloaction = this.appdata.getLocationById(sessionLocationId);

        if (sessionCapacity.extended_range) {
          console.log("Session has extended range: Offers can be place to all locations.");
          this.locations = locations;
        } else {
          console.log("Session has no extended range: Offers may to placed in the home division only.");
          let filteredLocations:ILocation[] = [];
          locations.forEach((l:ILocation)=>{
            if(l.division == homeloaction.division){
              filteredLocations.push(l);
            }
          })
          this.locations = filteredLocations;
        }
      } else {
        console.log("Waiting untill appdata was loaded in whole");
      }

    })
  }

  isMyOffer(offer: IOfferDetails) {
    if (this.appdata.getData().session.user == offer.capacity.id) {
      return true;
    } else {
      return false;
    }
  }

  delete(offer: IOfferDetails) {
    if (confirm("Möchtest Du das Angebot wirklich zurückziehen?")) {
      this.appdata.deleteOffer(offer);
    }
  }

  save() {
    if (confirm("Möchtest Du das Angebot erstellen?")) {
      let dateDetails = this.dateservice.createDateDetails(null, this.selectedDate);
      let offer: IOffer = {
        id: null,
        create_capacity_id: this.appdata.getData().session.user,
        date: this.selectedDate != null ? dateDetails.date : null,
        location_id: this.selectedLocation != null ? this.selectedLocation.id : null
      }
      this.appdata.createOffer(offer);
    }
  }

  public confirm(offer: IOfferDetails) {
    this.appdata.acceptOffer(offer);
    this.selectedOffer = offer;
    $("#sendrequest").modal('show');
  }

  ngOnInit() {
  }

}
