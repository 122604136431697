import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { ApplicationService } from 'src/shared/application.service';
import { Router } from '@angular/router';
import { IDateDetails } from 'src/model/application/idatedetails';

@Component({
  selector: 'app-eventmodal',
  templateUrl: './eventmodal.component.html',
  styleUrls: ['./eventmodal.component.css']
})
export class EventmodalComponent implements OnInit {

  public event:IMonthEvent;
  public events:IMonthEvent[];

  public isEditableEvent:boolean=false;

  constructor(private appdata: ApplicationService,private router:Router) { 
    this.appdata.getFoundEvents().subscribe((events:IMonthEvent[])=>{
      if(events != null){
         events.sort((a: IMonthEvent, b: IMonthEvent) => {
          return (a.beginning < b.beginning) ? -1 : 0;
        });

         this.events = events;
        this.event = events[0];
      }
    })
  }

  public edit(event:IMonthEvent){
    //this.appdata.editMonthEvent(event);
    this.router.navigate(["/edit/"+event.id]);
  }

  public create(event:IMonthEvent) {
    let sessionCapacity = this.appdata.getSessionCapayity();
    let sessionLocationId = sessionCapacity.homelocation;

    if (sessionLocationId === event.location.id || sessionCapacity.extended_range) {
      if (
        confirm(
          "Das Datum " +
          event.dateDetails.daynameFull +
          ", " +
          event.dateDetails.day +
          ". " +
          event.dateDetails.monthnameFull +
          " ist bereits belegt.\nWeitere Veranstaltung erstellen?"
        )
      ) {
        this.appdata.createNewEvent(event.dateDetails, event.location);
        this.router.navigate(["/edit/new/"]);
      }
    }
  }

  public downloadEvent(id:number){
    this.appdata.downloadEvent(id);
  }

  public close(){

  }

  public getBibleLink(passage:string){
    return "https://www.bibleserver.com/text/HFA/" + passage.replace(/\s/g, "");
  }

  ngOnInit() {
  }

}
