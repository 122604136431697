import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { HomescreenComponent } from './homescreen/homescreen.component';
import { UserinfoComponent } from './userinfo/userinfo.component';
import { ApplicationService } from 'src/shared/application.service';
import { MonthplanComponent } from './monthplan/monthplan.component';
import { EventmodalComponent } from './eventmodal/eventmodal.component';
import { NgDatepickerModule } from 'ng2-datepicker';
import { EditorComponent } from './editor/editor.component';
import { DatepickermodalComponent } from './datepickermodal/datepickermodal.component';
import { RegisterComponent } from './register/register.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { EventbookingmodalComponent } from './eventbookingmodal/eventbookingmodal.component';
import { PrintviewComponent } from './printview/printview.component';
import { SettingsComponent } from './settings/settings.component';
import { HelpmodalComponent } from './helpmodal/helpmodal.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import { CapacityManagementComponent } from './capacitymanagement/capacitymanagement.component';
import { EventselectionmodalComponent } from './eventselectionmodal/eventselectionmodal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    HomescreenComponent,
    UserinfoComponent,
    MonthplanComponent,
    EventmodalComponent,
    EditorComponent,
    DatepickermodalComponent,
    RegisterComponent,
    TransactionsComponent,
    EventbookingmodalComponent,
    PrintviewComponent,
    SettingsComponent,
    HelpmodalComponent,
    PasswordresetComponent,
    CapacityManagementComponent,
    EventselectionmodalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgDatepickerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(){
      
  }
}
