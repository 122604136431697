import { AuthentificationService } from '../authentification.service';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

export abstract class GenericService<T>{

  protected baseUrl = null;
  protected data: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  protected httpOptions;
  protected defaultServiceUrl: string;
  protected sessionUser = null;
  protected authtoken = null;
  protected serviceUrl = null;

  constructor( path: string, protected http: HttpClient, protected authService: AuthentificationService) {
/*
    if(environment.production){
      this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/";
    }else{
      this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/test/";
    }
*/
    this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/";

    this.defaultServiceUrl = this.baseUrl + path;
    this.serviceUrl = this.defaultServiceUrl;
    this.authService.getResponse().subscribe((response: IServerResponse<ISession>) => {
      if (response != null && response.operationState) {

        let authtoken = response.data.token;
        this.sessionUser = response.data.user;
        this.authtoken = authtoken;
        this.httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json',
            'Authorization': authtoken
          })
        };
      }
    });
  }

  public load(){
    //Load all Persons
    this.http.get<IServerResponse<T[]>>(this.defaultServiceUrl + "&ran=" + this.getRandomString(), this.httpOptions)
    .subscribe(
      (response) => {
        if (response != null) {
          let repsonseData: IServerResponse<T> = JSON.parse(JSON.stringify(response));
          this.data.next(repsonseData.data);
        }

      },
      (error:HttpErrorResponse)=>{
          console.error("Error while aksing the service: " + error.status);
          this.authService.checkAuthentification();
      });
  }

  public reload(urlParameter: Map<string, string>) {
    let parameterstring: string = "";
    if (urlParameter != null) {
      urlParameter.forEach((value: string, key: string) => {
        parameterstring += "&" + key + "=" + value;
      });
      
      this.serviceUrl = this.defaultServiceUrl + parameterstring;
      console.log("Reloading with Parameterset: " + parameterstring);
    }
    this.load();
  }

  public getEntries(): T {
    return this.data.value;
  }

  public onData(): BehaviorSubject<T> {
    return this.data;
  }

  protected getRandomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 16);
  }
}