import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { IOffer } from 'src/model/service/ioffer';
import { AuthentificationService } from '../authentification.service';
import { HttpClient } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { IOfferDetails } from 'src/model/application/iofferdetails';
import { ISetting } from 'src/model/service/isettings';
import { ILocationFilter } from 'src/model/application/factory/ilocationfilter';
import { ISettingDetail } from 'src/model/application/isettingdetail';

@Injectable({
  providedIn: 'root'
})
export class SettingService extends GenericService<ISetting[]>{
  
  

  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getSettings", http, authService);
    this.onData().subscribe((data: ISetting[]) => {
      if (data != null) {
        console.log("SettingService: Load of Settings successfully.");
      }
    })
  }

  setSettings(settings: ISettingDetail) {
    
    let newSettings:ISetting = {
      capacity_id: settings.capacity.id,
      location_filter: JSON.stringify(settings.locationFilter)
    }
    return this.http.post<IServerResponse<ISetting>>(this.baseUrl + "?action=setSettings", newSettings, this.httpOptions);
  }
  
}
