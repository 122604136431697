import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ICapacity } from 'src/model/service/icapacity';
import { ILocation } from 'src/model/service/ilocation';
import { AuthentificationService } from 'src/shared/authentification.service';
import { LocationService } from 'src/shared/modelservices/location.service';
import { IServerResponse } from 'src/model/service/serverresponse';
import { CapacityService } from 'src/shared/modelservices/capacity.service';
import { ICapacityCreation } from 'src/model/service/icapacitycreation';
import { HttpErrorResponse } from '@angular/common/http';
import { ApplicationService } from 'src/shared/application.service';

@Component({
  selector: 'app-register',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public go: boolean = false;
  public done:boolean = false;
  public locations: ILocation[] = [];
  public showAlert: boolean = false;
  public alertmessage: string = "";
  public password: string;
  public passwordRepeat:string;
  public capacity: ICapacity = {
    id: null,
    lastname: null,
    firstname: null,
    email: null,
    realperson: true,
    homelocation: null,
    leadingposition: false,
    active: false,
    extended_range: false,
    password: ""
  }

  constructor(
    private router: Router,
    private authservice: AuthentificationService,
    private locationService: LocationService,
    private capacities: CapacityService) {
    this.authservice.getResponse().subscribe((data) => {
      if (data && !this.done) {
        this.locationService.onData().subscribe((data: ILocation[]) => {
          if (data != null) {
            this.locations = data;
            this.go = true;
          }
        });
        this.locationService.load();
      }
    });
  }

  public alert(text: string) {
    this.showAlert = true;
    this.alertmessage = text;
  }

  public start() {
    this.authservice.systemLogin();
  }
  public toLogin() {
    this.done = false;
    this.go = false;
    this.authservice.logout(false);
    this.router.navigate(["/login"]);
  }
  public check() {

    if (this.capacity.email == null) {
      this.alert("Bitte gib eine E-Mail Adresse an!");
      return false;
    } else {
      let emailState = ApplicationService.validateEmail(this.capacity.email);

      if (!emailState) {
        this.alert("Bitte gib eine korrekte E-Mail Adresse an!");
        return false;
      } else {


        this.authservice.checkUser(this.capacity.email).subscribe((data: IServerResponse<boolean>) => {

          if (data.data) {
            this.alert("Die E-Mail Adresse '" + this.capacity.email + "' ist bereits bekannt!");
            return false;
          }

          if (this.capacity.lastname == null) {
            this.alert("Bitte gib einen Nachnamen an!");
            return false;
          }

          if (this.capacity.firstname == null) {
            this.alert("Bitte gib einen Vornamen an!");
            return false;
          }

          if (this.capacity.homelocation == null) {
            this.alert("Bitte wähle Deine Heimatgemeinde aus.");
            return false;
          }

          if (this.password == null) {
            this.alert("Bitte vergib ein Passwort.");
            return false;
          }

          if(this.passwordRepeat != this.password){
            this.alert("Die Passwörter stimmen nicht überein.");
            return false;
          }

          this.submit();
        });
      }
    }

  }
  public submit() {
    this.showAlert = false;
    this.go = false;
    let transportationObject: ICapacityCreation = {
      capacity: this.capacity,
      password: this.password
    }
    this.capacities.createCapacity(transportationObject).subscribe((response) => {
        let resp = <IServerResponse<ICapacity>>JSON.parse(JSON.stringify(response));
      console.log(resp);
        if(resp.operationState){
          this.done = true;
          this.authservice.logout(false);
        }else{
          this.alert("Fehler beim Anlegen des Benutzers: " + resp.message);
        }
      },
      (error: HttpErrorResponse) => {
        this.alert("Fehler beim Anlegen des Benutzers!");
      });
  }

  ngOnInit(){

  }

  ngOnDestroy() {
    this.go = false;
    this.done = false;
  }

}
