import { Component, OnInit } from '@angular/core';
import { DatetimeService } from 'src/shared/datetime.service';
import { DatepickerOptions } from 'ng2-datepicker';
import { IDateDetails } from 'src/model/application/idatedetails';
import { ApplicationService } from 'src/shared/application.service';
import { Router } from '@angular/router';
import { IMonthSelection } from 'src/model/application/imonthselection';

@Component({
  selector: 'app-datepickermodal',
  templateUrl: './datepickermodal.component.html',
  styleUrls: ['./datepickermodal.component.css']
})
export class DatepickermodalComponent implements OnInit {

  public selectedDate:Date;
  public options:DatepickerOptions = {};

  constructor(private router:Router, private appdata:ApplicationService, private dateservice:DatetimeService) { 
    this.options = dateservice.getDatepickerOptions();
    dateservice.getCurrentMonth().subscribe((data:IMonthSelection)=>{
      this.selectedDate = new Date();
      this.selectedDate.setDate(1);
      this.selectedDate.setMonth(data.monthNum-1);
      this.selectedDate.setFullYear(data.year);

    })
    
  }

  public select(){
    let date:IDateDetails = this.dateservice.createDateDetails(null,this.selectedDate);
    let capacity = this.appdata.getSessionCapayity();
    let location = this.appdata.getLocationById(capacity.homelocation);
    this.appdata.createNewEvent(date,location);
      this.router.navigate(["/edit/new/"]);
  }

  ngOnInit() {
  }

}
