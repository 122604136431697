import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ApplicationService } from 'src/shared/application.service';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { IEventType } from 'src/model/service/ieventtype';
import { ICapacity } from 'src/model/service/icapacity';
import { IFieldDetails } from 'src/model/application/ifielddetails';
import { IServerResponse } from 'src/model/service/serverresponse';
import { IEvent } from 'src/model/service/ievent';
import { ILocation } from 'src/model/service/ilocation';
import { Subscription } from 'rxjs';
import { MonthEventFactory } from 'src/model/application/factory/montheventfactory';

@Component({
  selector: 'app-editor',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  public selectedID: number = null;
  public event: IMonthEvent;
  public eventBackup: IMonthEvent;
  public title = "Editor";
  public badegtitle = null;
  public timeOptions = [];
  public eventTypes: IEventType[] = [];
  public capacityOptions: ICapacity[] = [];
  public locationOptions: ILocation[] = [];
  public showAlert: boolean = false;
  public textoffer:string;
  public static readonly PARAMETER_NEW = "new";
  public static readonly LIVESTREAM_TYPE_ID = 10001;
  private mode = "update";
  public showLoadingAnimation = false;
  public hideOfferingField = false;
  subscription: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private authservice: AuthentificationService, private appService: ApplicationService) {
    this.authservice.checkAuthentification();

    let id = this.route.snapshot.paramMap.get('id');
    if (id != EditorComponent.PARAMETER_NEW) {
      this.mode = "update";
      this.selectedID = parseInt(this.route.snapshot.paramMap.get('id'), 10);
      //Wait until Appdate was loaded
      this.subscription = this.appService.isLoading().subscribe((state: false) => {
        if (!state) {
          if (!isNaN(this.selectedID)) {

            this.setEvent(this.appService.getMonthEventById(this.selectedID));

          } else {
            alert("Cannot load " + this.selectedID)
          }
        }
      })
    } else {
      this.mode = "new";
      this.badegtitle = "NEU";
      this.setEvent(this.appService.getNewEvent());

      //Hier überschreibt er das initila gesetzte Feld.
      //this.event.fieldValues = this.appService.initEventTypeChange(this.event);
    }


  }
  public anyChange() {
    this.showAlert = true;
  }

  private setEvent(event: IMonthEvent) {

    if (event != null) {
      //Load Event with ID
      this.event = event;
      this.eventBackup = { ...{}, ...this.event };
      console.log("Loaded Event: " + JSON.stringify(this.event));

      //Set Title
      this.setTitle(this.event);

      //Load Event types
      this.eventTypes = this.appService.getEventTypes();

      //timeoptions
      this.timeOptions = this.appService.getTimeOptions();

      //capacities fitting to the event
      this.capacityOptions = this.appService.getCapacitiesByEvent(this.event);

      let sessionuser = this.appService.getSessionCapayity();
      this.locationOptions = this.appService.getLocations(sessionuser);

      if(this.event.type.id == 1 || this.event.type.id == 10001){
        this.textoffer = this.appService.getTextByDate(this.event.dateDetails.dateobject);
      }

      if(this.event.type.id == EditorComponent.LIVESTREAM_TYPE_ID){
        this.event.global_offerring = true;        
      }


      if(parseInt(this.event.type.id+"",10) === MonthEventFactory.EDITABLE_EVENT_TYPE_ID){
        this.hideOfferingField = true;
      }else{
        this.hideOfferingField = false;
      }
      

    } else {
      console.warn("Event " + this.selectedID + " is null");
    }
  }

  public onTypeChange() {

    if (this.event.type.id == this.eventBackup.type.id) {
      console.log("Init Event type re-selected: Setting existing field values");
      this.event.fieldValues = this.eventBackup.fieldValues;
      this.showAlert = false;
    } else {
      this.anyChange();
      this.event.fieldValues = this.appService.initEventTypeChange(this.event);
    }

    const parsedEventTypeId = parseInt(this.event.type.id+"",10);
    if(parsedEventTypeId === MonthEventFactory.EDITABLE_EVENT_TYPE_ID){
      this.hideOfferingField = true;
    }else{
      this.hideOfferingField = false;
    }

    if(parsedEventTypeId == EditorComponent.LIVESTREAM_TYPE_ID){
      this.event.global_offerring = true;        
    }

  }

  public acceptTextOffer(){
    this.event.fieldValues.forEach((field: IFieldDetails) => {
      if(field.id == 3 || field.id == 33){
        field.value = this.textoffer;
        field.valueResolved = this.textoffer;
      }
    });
  }

  public abort() {
    this.event = this.eventBackup;
    this.eventBackup = null;
    this.showAlert = false;
    this.router.navigate(["/home"]);
  }

  public save() {
    window.scroll(0, 0);
    this.showLoadingAnimation = true;
    //Writing resolvec value also into the value
    this.showAlert = false;
    this.event.fieldValues.forEach((field: IFieldDetails) => {


      if (field.type == 'CHECK' || field.type == "TEXT" || field.type == "URL") {
        field.value = <string>field.valueResolved;
      } else {
        if (field.type == 'CHOICE_CAPACITY') {
          if (field.valueResolved != null) {
            let cap: ICapacity = <ICapacity>field.valueResolved;
            field.value = cap.id;
          } else {
            field.value = null;
          }
        }
        if (field.type == 'CHOICE_LOCATION') {
          if (field.valueResolved != null) {
            let loc: ILocation = <ILocation>field.valueResolved;
            field.value = ""+loc.id;
          } else {
            field.value = null;
          }
        }
      }
    })

    if (this.mode == "update") {
      this.appService.updateEvent(this.event).subscribe((data) => {
        let response: IServerResponse<IEvent> = JSON.parse(JSON.stringify(data)); // :)
        if (response.operationState) {
          this.router.navigate(["/home"]);
          //location.href = "/home";
        } else {
          alert("Fehler beim Löschen: " + response.message);
        }
      });
    } else {
      this.appService.postEvent(this.event).subscribe((data) => {
        let response: IServerResponse<IEvent> = JSON.parse(JSON.stringify(data)); // :)
        if (response.operationState) {
          this.router.navigate(["/home"]);
          //location.href = "/home";
        } else {
          alert("Fehler beim Löschen: " + response.message);
        }
      });
    }

  }

  public delete() {
    if (confirm("Wollen Sie diese Veranstaltung wirklich löschen?")) {
      this.showLoadingAnimation = true;
      this.appService.deleteEvent(this.event).subscribe((data) => {
        let response: IServerResponse<IEvent> = JSON.parse(JSON.stringify(data)); // :)
        if (response.operationState) {
          this.router.navigate(["/home"]);
          //location.href = "/home";
        } else {
          alert("Fehler beim Löschen: " + response.message);
        }

      })
    }
  }

  public setTitle(event: IMonthEvent) {
    this.title = event.dateDetails.daynameFull + ", "
      + event.dateDetails.day + " . "
      + event.dateDetails.monthnameFull + " "
      + event.dateDetails.year;
  }

  ngOnInit() {

  }

  ngOnDestroy(){
    if (!!this.subscription) this.subscription.unsubscribe();
  }
}
