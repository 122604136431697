import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationService } from 'src/shared/application.service';
import { ISettingDetail } from 'src/model/application/isettingdetail';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ILocation } from 'src/model/service/ilocation';
import { IDivision } from 'src/model/service/idivision';
import { Router } from '@angular/router';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISetting } from 'src/model/service/isettings';
import { ReplaceSource } from 'webpack-sources';
import { ICapacity } from 'src/model/service/icapacity';

@Component({
  selector: 'app-settings',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  settings: ISettingDetail = null;
  locations: ILocation[] = [];
  division: IDivision;
  badgeTitle = "Einstellungen";
  showData = false;
  public showmessage = 0;
  capacity: ICapacity;
  message = "";

  constructor(private authservice: AuthentificationService, private appdata: ApplicationService, private router: Router) {

    this.authservice.checkAuthentification();

    this.appdata.isLoading().subscribe((state: boolean) => {
      if (!state) {
        this.settings = this.appdata.getSettings();
        let sessionUserCapacity = this.appdata.getCapacityBId(this.appdata.getSession().user);
        this.locations = this.appdata.getLocations(sessionUserCapacity);

        this.capacity = sessionUserCapacity;
        if (this.settings == null) {

          let allLocationIds = [];
          this.locations.forEach((l:ILocation)=>{
            allLocationIds.push(l.id);
          })

          this.settings = {
            capacity: sessionUserCapacity,
            locationFilter: {
              locations: allLocationIds
            }
          }
        }

        if (this.locations != null && this.locations.length > 0) {
          let homelocation = this.appdata.getLocationById(sessionUserCapacity.homelocation);
          this.division = this.appdata.getDivisionById(homelocation.division);
        }

        this.showData = true;
      }
    })
  }

  public add(locationId) {
    this.settings.locationFilter.locations.push(locationId);
  }
  public remove(locationId) {
    for (let i = 0; i < this.settings.locationFilter.locations.length; i++) {
      let elem = this.settings.locationFilter.locations[i];
      if (elem == locationId) {
        this.settings.locationFilter.locations.splice(i, 1);
      }
    }
  }


  public saveUser() {
    window.scroll(0, 0);
    let emailState = ApplicationService.validateEmail(this.capacity.email);
    if (!emailState) {
      this.failue("Bitte gib eine korrekte E-Mail Adresse an!");
    } else {
      if (this.capacity.password == "" || this.capacity.password == null || this.capacity.password == " ") {
        this.failue("Bitte gib ein Passwort ein.");
      } else {
        this.appdata.updateCapacity(this.capacity).subscribe((data) => {
          let response = <IServerResponse<boolean>>JSON.parse(JSON.stringify(data));
          if (response.operationState) {
            this.success("Benutzerdaten wurden übernommen.")
          } else {
            this.failue("Fehler beim Speichern.");
          }
        })
      }
    }


  }

  public save() {
    window.scroll(0, 0);
    if (this.settings.locationFilter.locations.length == 0) {
      this.failue("Bitte wähle mind. eine Gemeinde aus!");
    } else {
      this.appdata.setSettings(this.settings).subscribe((data) => {
        let response = <IServerResponse<ISetting>>JSON.parse(JSON.stringify(data));
        if (response.operationState) {
          this.success("Einstellungen wurden übernommen.")
        } else {
          this.failue("Fehler beim Speichern.");
        }
        this.appdata.reloadSettings();
      });
    }

  }

  public success(text) {
    this.showmessage = 1;
    this.message = text;
    setTimeout(() => { this.showmessage = 0 }, 2000);
  }

  public failue(text) {
    this.showmessage = 2;
    this.message = text;
    setTimeout(() => { this.showmessage = 0 }, 2000);
  }

  public isSelected(locationId: number) {
    let state = false;
    if (this.settings != null && this.settings.locationFilter != null) {
      let filterLocations = this.settings.locationFilter.locations;
      if (filterLocations.length > 0) {
        filterLocations.forEach((lid: number) => {
          if (lid == locationId) {
            state = true;
          }
        })
      } else {
        state = false;
      }
    } else {
      state = false;
    }
    return state;
  }

  ngOnInit() {
  }

}
