import { Component, OnInit } from '@angular/core';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ISession } from 'src/model/service/isession';
import { IServerResponse } from 'src/model/service/serverresponse';
import { DatetimeService } from 'src/shared/datetime.service';
import { IMonthSelection } from 'src/model/application/imonthselection';
import { Router } from '@angular/router';
import { ApplicationService } from 'src/shared/application.service';
import { IDivision } from 'src/model/service/idivision';
import { ICapacity } from 'src/model/service/icapacity';

@Component({
  selector: 'app-userinfo',
  templateUrl: './userinfo.component.html',
  styleUrls: ['./userinfo.component.css']
})
export class UserinfoComponent implements OnInit {

  public userid: string;
  public division: IDivision;
  public showData: boolean = false;
  public sessionUserCapacity:ICapacity;

  constructor(private service: AuthentificationService, private router: Router, private appdata: ApplicationService) {

    this.appdata.isLoading().subscribe((state: boolean) => {
      if (!state) {

        this.userid = this.appdata.getSession().user;
        this.division = this.appdata.getHomeDivision();
        this.sessionUserCapacity = this.appdata.getSessionCapayity();
        this.showData = true;
      }

    });


  }
  public settings() {
    this.router.navigate(["/settings"]);
  }

  public userData() {

  }

  ngOnInit() {
  }

}
