import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { IOffer } from 'src/model/service/ioffer';
import { AuthentificationService } from '../authentification.service';
import { HttpClient } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { IOfferDetails } from 'src/model/application/iofferdetails';

@Injectable({
  providedIn: 'root'
})
export class OfferService extends GenericService<IOffer[]>{
  

  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getoffers", http, authService);
    this.onData().subscribe((data: IOffer[]) => {
      if (data != null) {
        console.log("OfferService: Load of " + data.length + " Offers successfully loaded.");
      }
    })
  }

  createOffer(offer: IOffer) {
    return this.http.post<IServerResponse<IOffer>>(this.baseUrl + "?action=createOffer", offer, this.httpOptions);
  }
  deleteOffer(offer: IOfferDetails) {
    return this.http.post<IServerResponse<IOffer>>(this.baseUrl + "?action=deleteOffer", offer, this.httpOptions);
  }
}
