import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/shared/authentification.service';
import { IServerResponse } from 'src/model/service/serverresponse';

@Component({
  selector: 'app-passwordreset',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordresetComponent implements OnInit {

  public showAlert: boolean = false;
  public alertmessage: string = "";
  public ready = false;

  constructor(private authservice: AuthentificationService, private router: Router) {
    this.authservice.systemLogin();
  }

  public abort() {
    this.router.navigate(["/login"]);
  }

  public alert(text: string) {
    this.showAlert = true;
    this.alertmessage = text;
  }

  public resetPassword(user: string) {
    this.authservice.checkUser(user).subscribe((data: IServerResponse<boolean>) => {
      if (!data.data) {
        this.alert("Der Benutzer '" + user + "' ist nicht bekannt!");
        return false;
      }else{
        this.authservice.resetUser(user).subscribe((data: IServerResponse<boolean>) => {
          this.ready = true;
          this.authservice.logout(false);
        });
      }
    });
  }

  ngOnInit() {
  }

}
