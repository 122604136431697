import { IEvent } from "src/model/service/ievent";
import { ILocation } from "src/model/service/ilocation";
import { IMonthEvent } from "../imonthevent";
import { IApplicationData } from "../iappclicationdata";
import { IDateDetails } from "../idatedetails";
import { IEventType } from "src/model/service/ieventtype";
import { ICapacity } from "src/model/service/icapacity";
import { IFieldValue } from "src/model/service/ifieldvalue";
import { IField } from "src/model/service/ifield";
import { IFieldDetails } from "../ifielddetails";
import { DatetimeService } from "src/shared/datetime.service";
import { BaseFactory } from "./basefactory";
import { IOfferDetails } from "../iofferdetails";
import { IOffer } from "src/model/service/ioffer";

export class MonthEventFactory extends BaseFactory {
  private monthplan: Map<string, IMonthEvent[]>;
  private allDates: IDateDetails[] = [];
  private targetDivsionId = -1;
  private locationKeys:Map<string,ILocation> = new Map();
  public static readonly EDITABLE_EVENT_TYPE_ID = 9999;
  constructor(
    appData: IApplicationData,
    dateserver: DatetimeService,
    targetDivsionId: number
  ) {
    super(appData, dateserver);
    this.targetDivsionId = targetDivsionId;

    this.process();
  }

  public static distinctDateList(allDates: IDateDetails[]): IDateDetails[] {
    const result: IDateDetails[] = [];
    const map: Map<string, boolean> = new Map<string, boolean>();
    for (const item of allDates) {
      if (map.get(item.date) == null) {
        map.set(item.date, true);
        result.push(item);
      }
    }
    return result;
  }

  protected initMap() {
    this.monthplan = new Map<string, IMonthEvent[]>();
    let isExtendedUser: boolean = this.getCapacityById(
      this.appData.session.user
    ).extended_range;
    for (let i = 0; i < this.appData.locations.length; i++) {
      let location = this.appData.locations[i];
      let add = false;
      if (isExtendedUser) {
        add = true;
      } else {
        if (location.division == this.targetDivsionId) {
          add = true;
        } else {
          add = false;
        }
      }
      if (add) {
        let locationKey = MonthEventFactory.getLocationKey(location);
        if (!this.monthplan.has(locationKey)) {
          this.monthplan.set(locationKey, []);
        }
      }
    }
  }

  protected process() {
    this.initMap();
    this.allDates = [];

    for (let i = 0; i < this.appData.events.length; i++) {
      let eventObj = this.appData.events[i];
      let e: IMonthEvent = this.processSingleEvent(eventObj);

      let locationKey = MonthEventFactory.getLocationKey(e.location);
      if (this.monthplan.has(locationKey)) {
        //console.log("Adding new event for " + locationKey);
        this.monthplan.get(locationKey).push(e);
      } else {
        console.warn(
          "Location with key " + locationKey + " not found! Dropping events"
        );
      }
    }
    this.allDates = MonthEventFactory.distinctDateList(this.allDates);
  }

  public static getLocationKey(location: ILocation) {
    let name = null;
    if (location != null) {
      if (
        location.city.indexOf(" ") != -1 ||
        location.city.indexOf("-") != -1
      ) {
        let parts = location.city.split(" ");
        if(parts == null || parts.length === 1){
          parts = location.city.split("-");
        }
        if(parts.length > 1){
          name = parts[0].substring(0, 3) + "-" + parts[1].substring(0, 3);
        }else{
          name = parts[0].substring(0, 3) + "...";
        }
      } else {
        name = location.city.substring(0, 3);
      }
    }

    return name.toUpperCase();
  }

  public createDummy(
    capacity: ICapacity,
    location: ILocation,
    dateDetails: IDateDetails,
    relatedOffer?: IOfferDetails
  ): IMonthEvent {
    if (capacity != null) {
      //let location = this.getLocationById(me.homelocation);
      let type = this.getTypeById(1);

      let fields = this.getFieldsByEventType(type, relatedOffer);
      let offer: IOffer = null;
      if (relatedOffer != null) {
        offer = {
          id: relatedOffer.id,
          date: relatedOffer.date.date,
          location_id: relatedOffer.location ? relatedOffer.location.id : null,
          create_capacity_id: relatedOffer.capacity.id
        };
      }

      const obj: IMonthEvent = {
        id: null,
        dateDetails: dateDetails,
        type: type,
        lead_capacity: relatedOffer != null ? relatedOffer.capacity : capacity,
        location: location,
        beginning: "10:00",
        fieldValues: fields,
        notes: null,
        created: null,
        create_capacity_id: null,
        updated: null,
        update_capacity_id: null,
        isEditable: true,
        relatedOffer: offer,
        global_offerring: false
      };
      return obj;
    } else {
      return null;
    }
  }

  private processSingleEvent(eventObj: IEvent): IMonthEvent {
    let dateDetails: IDateDetails = this.dateserver.createDateDetails(
      eventObj.date
    );
    this.allDates.push(dateDetails);

    const obj = {
      id: eventObj.id,
      dateDetails: dateDetails,
      type: this.getTypeById(eventObj.type),
      lead_capacity: this.getCapacityById(eventObj.lead_capacity_id),
      location: this.getLocationById(eventObj.location_id),
      beginning: eventObj.beginning,
      fieldValues: this.resolveFieldValuesByEventId(eventObj),
      notes: eventObj.notes,
      created: eventObj.created,
      create_capacity_id: eventObj.create_capacity_id,
      updated: eventObj.updated,
      update_capacity_id: eventObj.update_capacity_id,
      isEditable: this.isEditableEvent(eventObj),
      relatedOffer: null,
      global_offerring: eventObj.global_offerring
    };
    return obj;
  }

  private isEditableEvent(event: IEvent): boolean {
    if (event != null) {
      try {
        let sessionUser = this.appData.session.user;
        let user = this.getCapacityById(sessionUser);
      
        return event.lead_capacity_id == sessionUser || event.location_id == user.homelocation || user.extended_range || event.type == MonthEventFactory.EDITABLE_EVENT_TYPE_ID;
      } catch (e) {
        console.error("fehler: "+e);
        return false;
      }
    } else {
      return false;
    }
  }

  public getMonthEventById(id: number) {
    let entry: IMonthEvent;
    this.monthplan.forEach((value: IMonthEvent[], key: string) => {
      if (value != null && value.length > 0) {
        value.forEach((event: IMonthEvent) => {
          if (event.id == id) {
            entry = event;
          }
        });
      }
    });

    return entry;
  }

  public getMonthEventByDate(date: Date, locationId?: number): IMonthEvent[] {
    let foundEvents: IMonthEvent[] = [];
    this.monthplan.forEach((value: IMonthEvent[], key: string) => {
      if (value != null && value.length > 0) {
        value.forEach((event: IMonthEvent) => {
          //console.log(event.dateDetails.dateobject + " <-> " + date);
          if (event.dateDetails.dateobject.getTime() == date.getTime()) {
            if (locationId != null) {
              if (locationId == event.location.id) {
                foundEvents.push(event);
              }
            } else {
              foundEvents.push(event);
            }
          }
        });
      }
    });

    return foundEvents;
  }

  private getFieldValue(eventId: number, field: IField): IFieldDetails {
    let foundEntry: IFieldDetails;

    this.appData.values.forEach((fv: IFieldValue) => {
      if (fv.event_id === eventId && fv.field_id == field.id) {
        let valueRes = null;

        if (field.field_type == "TEXT" || field.field_type == "URL") {
          valueRes = fv.field_value;
        }

        if (field.field_type == "CHOICE_CAPACITY") {
          valueRes = this.getCapacityById(fv.field_value);
        }

        if (field.field_type == "CHOICE_LOCATION") {
          let id = parseInt(fv.field_value, 10);
          valueRes = this.getLocationById(id);
        }

        if (field.field_type == "CHECK") {
          if (
            fv.field_value === "TRUE" ||
            fv.field_value === "true" ||
            fv.field_value == "1"
          ) {
            valueRes = true;
          } else {
            valueRes = false;
          }
        }

        foundEntry = {
          id: fv.field_id,
          name: field.field_name,
          event_id: fv.event_id,
          event_type_id: field.event_type_id,
          type: field.field_type,
          value: fv.field_value,
          valueResolved: valueRes
        };
      }
    });

    if (foundEntry == null) {
      foundEntry = {
        id: field.id,
        name: field.field_name,
        event_id: eventId,
        event_type_id: field.event_type_id,
        type: field.field_type,
        value: null,
        valueResolved: null
      };
    }

    return foundEntry;
  }

  private resolveFieldValuesByEventId(event: IEvent): IFieldDetails[] {
    let foundEntries: IFieldDetails[] = [];

    this.appData.fields.forEach((field: IField) => {
      //Zum Event-Typ passende Felder durchlaufen
      if (field.event_type_id == event.type) {
        foundEntries.push(this.getFieldValue(event.id, field));
      }
    });

    return foundEntries;
  }

  public getFieldsByEventType(
    eventType: IEventType,
    relatedOffer?: IOfferDetails
  ): IFieldDetails[] {
    if (eventType != null) {
      let foundEntries: IFieldDetails[] = [];
      this.appData.fields.forEach((field: IField) => {
        let obj: IFieldDetails;
        let value = null;
        let valueResolved = null;

        //Zum Event-Typ passende Felder durchlaufen
        if (field.event_type_id == eventType.id) {
          if (relatedOffer != null && field.id == 5) {
            (value = relatedOffer.capacity.id),
              (valueResolved = relatedOffer.capacity);
          }

          obj = {
            id: field.id,
            name: field.field_name,
            event_id: -1,
            event_type_id: eventType.id,
            type: field.field_type,
            value: value,
            valueResolved: valueResolved
          };

          foundEntries.push(obj);
        }
      });
      return foundEntries;
    } else {
      return null;
    }
  }

  public getMonthplan(): Map<string, IMonthEvent[]> {
    return this.monthplan;
  }

  public getAllDates(): IDateDetails[] {
    return this.allDates;
  }

  public getLocationEvents(location?: ILocation): IMonthEvent[] {
    let foundEntries: IMonthEvent[] = [];

    if (this.monthplan != null) {
      let allEvents = this.monthplan.get(
        MonthEventFactory.getLocationKey(location)
      );

      if (allEvents != null && allEvents.length > 0) {
        allEvents.forEach((event: IMonthEvent) => {
          if (event.dateDetails.dateobject >= new Date()) {
            foundEntries.push(event);
          }
        });
      }
    }

    return foundEntries;
  }
}
