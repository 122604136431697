import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { AuthentificationService } from '../authentification.service';
import { IEvent } from 'src/model/service/ievent';
import { IDivision } from 'src/model/service/idivision';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DivisionService extends GenericService<IDivision[]>{

  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getdivisions", http, authService);
    this.onData().subscribe((data: IDivision[]) => {
      if (data != null) {
        console.log("DivisionService: Load of " + data.length + " Divisions successfully loaded.");
      }
    })
  }
  public getDivisionById(id:number):IDivision{
    let entry:IDivision = null;
    if(this.getEntries() != null){

      this.getEntries().forEach((e:IDivision)=>{
        if(e.id == id){
          entry = e;
        }
      });
    }
    return entry;
  }
}
