import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationService } from 'src/shared/application.service';
import { DatetimeService } from 'src/shared/datetime.service';
import { ICapacity } from 'src/model/service/icapacity';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ICapacityCreation } from 'src/model/service/icapacitycreation';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/shared/authentification.service';
import { LocationService } from 'src/shared/modelservices/location.service';
import { CapacityService } from 'src/shared/modelservices/capacity.service';

@Component({
  selector: 'app-capacitymanagement',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './capacitymanagement.component.html',
  styleUrls: ['./capacitymanagement.component.css']
})
export class CapacityManagementComponent implements OnInit {


  public capacities: ICapacity[];
  public showmessage = 0;
  public title = "Benutzerverwaltung";
  public showAlert: boolean = false;
  public message = "";
  public locationDummies: ICapacity[] = []
  public defaultCapacity: ICapacity = {
    id: null,
    lastname: null,
    firstname: null,
    email: null,
    realperson: true,
    homelocation: null,
    leadingposition: false,
    active: true,
    extended_range: false,
    password: ""
  }
  public selectedCapacity = null;
  public sessionUserCapacity: ICapacity;

  constructor(
    private appdata: ApplicationService) {

    this.appdata.isLoading().subscribe((state: boolean) => {
      if (!state) {
        this.capacities = this.appdata.getInActiveCapacities();
        this.sessionUserCapacity = this.appdata.getSessionCapayity();
        let locationId = this.sessionUserCapacity.homelocation;
        let location = this.appdata.getLocationById(locationId);
        this.defaultCapacity.homelocation = locationId;
        let locationUsers = this.appdata.getCapacitiesByLocation(location);
        locationUsers.forEach((l: ICapacity) => {
          if (l.email == null) {
            this.locationDummies.push(l);
          }
        });
        this.sortDummies();
        this.initCapacity();
      }
    });

  }

  public getLocationName(locationId: number) {
    return this.appdata.getLocationById(locationId).name;
  }

  public disline(c: ICapacity) {
    if (confirm("Soll " + c.firstname + " wirklich abgelehnt werden?")) {
      this.appdata.dislineCapacity(c).subscribe((data) => {
        let response = <IServerResponse<ICapacity>>JSON.parse(JSON.stringify(data));
        if (response.operationState) {
          this.success("Benutzer abgelehnt.")
        } else {
          this.failue("Fehler beim ablehnen.");
        }
      })
    }
    this.delete(c);
  }

  public accept(c: ICapacity) {
    if (confirm("Soll " + c.firstname + " wirklich freigeschaltet werden?")) {
      this.appdata.acceptCapacity(c).subscribe((data) => {
        let response = <IServerResponse<ICapacity>>JSON.parse(JSON.stringify(data));
        if (response.operationState) {
          this.success("Benutzer freigeschaltet.")
        } else {
          this.failue("Fehler beim freischalten.");
        }
      })
    }
    this.delete(c);
  }

  private delete(capacity: ICapacity) {
    if (this.capacities != null) {
      for (let i = 0; i < this.capacities.length; i++) {
        if (this.capacities[i].id == capacity.id) {
          this.capacities.splice(i, 1);
        }
      }
    }
  }

  public success(text) {
    this.showmessage = 1;
    this.message = text;
    window.scroll(0, 0);
    setTimeout(() => { this.showmessage = 0 }, 2000);
  }

  public failue(text) {
    this.showmessage = 2;
    this.message = text;
    window.scroll(0, 0);
    setTimeout(() => { this.showmessage = 0 }, 2000);
  }

  public check() {

    if (this.selectedCapacity.lastname == null) {
      this.failue("Bitte gib einen Nachnamen an!");
      return false;
    }

    if (this.selectedCapacity.firstname == null) {
      this.failue("Bitte gib einen Vornamen an!");
      return false;
    }

    if (this.selectedCapacity.homelocation == null) {
      this.failue("Bitte wähle Deine Heimatgemeinde aus.");
      return false;
    }

    this.submit();
  }

  public alert(text: string) {
    this.showAlert = true;
    this.message = text;
  }

  public deleteDummy(c: ICapacity) {

    for (let i = 0; i < this.locationDummies.length; i++) {
      if (c.id === this.locationDummies[i].id) {
        this.locationDummies.splice(i, 1);
        break;
      }
    }
    this.sortDummies();

    this.appdata.dislineCapacity(c).subscribe((data) => {
      let response = <IServerResponse<ICapacity>>JSON.parse(JSON.stringify(data));
      if (response.operationState) {
        this.success("Benutzer gelöscht.");


      } else {
        this.failue("Fehler beim löschen.");
      }
    });

  }

  private sortDummies() {
    this.locationDummies.sort((a: ICapacity, b: ICapacity) => {
      var textA = a.lastname.toUpperCase();
      var textB = b.lastname.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });
  }

  public submit() {
    this.showAlert = false;
    let transportationObject: ICapacityCreation = {
      capacity: this.selectedCapacity,
      password: null
    }
    this.appdata.createNewDummyCapacity(transportationObject).subscribe((response) => {
      let resp = <IServerResponse<ICapacity>>JSON.parse(JSON.stringify(response));
      if (resp.operationState) {
        this.success(resp.message);
        this.locationDummies.push(this.selectedCapacity);
        this.sortDummies();
        this.initCapacity();
      } else {
        this.failue(resp.message);
      }
    },
      (error: HttpErrorResponse) => {
        this.failue("Fehler beim Anlegen des Benutzers!");
      });
  }

  private initCapacity() {
    this.selectedCapacity = this.defaultCapacity;
  }

  ngOnInit() {
  }

}
