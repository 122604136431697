import { IApplicationData } from '../iappclicationdata';

import { DatetimeService } from 'src/shared/datetime.service';
import { BaseFactory } from './basefactory';
import { IOfferDetails } from '../iofferdetails';
import { IOffer } from 'src/model/service/ioffer';

export class OfferFactory extends BaseFactory{

    private offers:IOfferDetails[];

    constructor(appData: IApplicationData, dateserver: DatetimeService) {
        super(appData,dateserver)
        this.process();
    }

    protected process() {
        this.offers = [];
        this.appData.offers.forEach((o:IOffer)=>{
          this.offers.push({
            id: o.id,
            date: o.date != null? this.dateserver.createDateDetails(o.date) : null ,
            location: this.getLocationById(o.location_id),
            capacity: this.getCapacityById(o.create_capacity_id)
          });
        });
    }

    public getOffers(){
        return this.offers;
    }
}