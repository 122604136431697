import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ISession } from 'src/model/service/isession';
import { DatetimeService } from './datetime.service';
import { GenericService } from './modelservices/generic.service';
import { ApplicationService } from './application.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthentificationService {


    private currentSession: ISession = null;
    private serverRepsonse: BehaviorSubject<IServerResponse<ISession>> = new BehaviorSubject<IServerResponse<ISession>>(null);
    private sessionCountDown: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private sessionTimeout: number;
    private sessionTimeoutTimer;
    private desiredUrl: string;
    private APIuser = "SYSTEM";
    private APIpass = "fko9elsfas2fsd_(/p33234nsdf$5_ad*§pa0du";
    protected baseUrl = null;
    private defaultHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };


    constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) {
        /*if(environment.production){
            this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/";
          }else{
            this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/test/";
          }
          */
        this.baseUrl = "https://gemeindeplaner.apostolisch.de/service/";
    }

    public setDesiredUrl(url: string) {
        if (window.location.origin.indexOf(url) == -1) {
            this.desiredUrl = url;
            console.log("window.location.origin: " + window.location.origin);
            console.log("Got next URL: " + url);
        } else {
            this.desiredUrl = null;
        }

    }

    private getNextUrl() {
        let next: string;
        if (this.desiredUrl != null) {
            if (this.desiredUrl.indexOf("login") != -1 || this.desiredUrl != window.location.origin) {
                next = "/home";
            } else {
                next = this.desiredUrl;
            }

        } else {
            next = "/home";
        }
        console.log("--> Next: " + next);
        return next;
    }

    public getCurrentSession(): ISession {
        return this.currentSession;
    }

    public getResponse(): BehaviorSubject<IServerResponse<ISession>> {
        return this.serverRepsonse;
    }

    public logout(redirect: boolean) {
        console.log("Logout():Killing Session, local storage and last server response.");
        this.updateRequestOptions(null);
        this.serverRepsonse.next(null);
        this.currentSession = null;
        localStorage.setItem("SESSION", null);
        localStorage.removeItem("SESSION");

        if (redirect) {
            this.router.navigate(["/login"]);
        }
    }

    public checkAuthentification() {
        console.log("checkAuthentification()")
        if (this.serverRepsonse.value == null && localStorage.getItem("SESSION") == null) {
            console.log("No Session found.");
            this.logout(true);
        } else {
            console.log("Reusing stored Session.");
            let storedData = localStorage.getItem("SESSION");
            let responseReUsed: IServerResponse<ISession> = JSON.parse(storedData);

            if (responseReUsed == null || DatetimeService.parseDate(responseReUsed.data.expires) < new Date()) {
                console.log("Session is expired!");
                this.logout(true);
            } else {
                this.serverRepsonse.next(responseReUsed);
                this.updateSession(responseReUsed);
                this.updateRequestOptions(responseReUsed);
                console.log("Session still valid! Expires:" + this.currentSession.expireDate + " Now:" + new Date());

                //this.router.navigate([this.getNextUrl()]);

            }
        }
    }

    private updateRequestOptions(response: IServerResponse<ISession>) {
        if (response != null) {
            this.httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Authorization': response.data.token
                })
            };
        } else {
            this.httpOptions = this.defaultHttpOptions;
        }

    }

    public systemLogin() {
        this.authentificate(this.APIuser, this.APIpass, false);
    }

    public checkUser(userEmail) {
        let url = this.baseUrl + "?action=checkUser&user=" + userEmail;
        return this.http.post(url, null, this.httpOptions);
    }

    public resetUser(user: string) {
        let url = this.baseUrl + "?action=resetUser&user=" + user;
        return this.http.post(url, null, this.httpOptions);
    }

    public authentificate(user: string, password: string, redirect: boolean): void {
        const url = this.baseUrl + "?action=authentification";
        this.http.post(url, { user: user, password: password }, this.httpOptions)
            .subscribe((response: IServerResponse<ISession>) => {
                console.log("authentificate(): Got response: " + JSON.stringify(response));

                this.serverRepsonse.next(response);
                if (response != null) {
                    if (response.operationState && response.operationName == "auth") {
                        this.updateRequestOptions(response);
                        this.updateSession(response);

                        if (redirect) {
                            this.router.navigate([this.getNextUrl()]);
                        }

                    }
                }
            },
                (error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        this.router.navigate(["/home"]);
                    }
                });
    }

    public getSessionTimeout() {
        return this.sessionCountDown;
    }

    private startCount() {

        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = this.sessionTimeout - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        let timeoutString = hours + "h " + minutes + "m " + seconds + "s ";
        this.sessionCountDown.next(timeoutString);

        // If the count down is finished, write some text 
        if (distance < 0) {
            clearInterval(this.sessionTimeoutTimer);
            this.checkAuthentification();
        }


    }

    private updateSession(response: IServerResponse<ISession>) {
        if (response != null) {
            this.currentSession = response.data;

            let parsedDate = DatetimeService.parseDate(response.data.expires);
            if (parsedDate instanceof Date && !isNaN(parsedDate.getTime())) {
                this.currentSession.expireDate = new Date(parsedDate);

                this.sessionTimeout = new Date(parsedDate).getTime();
                this.sessionTimeoutTimer = setInterval(() => this.startCount(), 1000);

            } else {
                let yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                this.currentSession.expireDate = yesterday; // Session is now expired :)
            }

            localStorage.setItem("SESSION", JSON.stringify(response));
        }
    }
}