import { Component } from '@angular/core';
import { AuthentificationService } from 'src/shared/authentification.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'capa-ui';
  constructor(private authservice: AuthentificationService) {

    if(location.pathname.indexOf("register") == -1){
      this.authservice.setDesiredUrl(location.pathname);
      this.authservice.checkAuthentification();
    }
    
  }
}
