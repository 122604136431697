import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { IMonthSelection } from 'src/model/application/imonthselection';
import { ICapacity } from 'src/model/service/icapacity';
import { ActivatedRoute } from '@angular/router';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ApplicationService } from 'src/shared/application.service';
import { DatetimeService } from 'src/shared/datetime.service';
import { MonthEventFactory } from 'src/model/application/factory/montheventfactory';
import { IFieldDetails } from 'src/model/application/ifielddetails';
import { validateConfig } from '@angular/router/src/config';
import { ILocation } from 'src/model/service/ilocation';
import { IFieldValue } from 'src/model/service/ifieldvalue';

@Component({
  selector: 'app-printview',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './printview.component.html',
  styleUrls: ['./printview.component.css']
})
export class PrintviewComponent implements OnInit {

  private monthplan: Map<string, IMonthEvent[]>;
  private events: IMonthEvent[] = [];
  private location: ILocation;
  public showdata: boolean = false;
  public badgeTitle = null;
  private month: IMonthSelection;
  private capacities: ICapacity[] = [];

  constructor(
    private route: ActivatedRoute,
    private authservice: AuthentificationService,
    private appData: ApplicationService,
    private datetime: DatetimeService) {

    this.authservice.checkAuthentification();

    this.appData.isLoading().subscribe((state: boolean) => {

      if (!state) {
        let locationIdString: string = this.route.snapshot.paramMap.get('location');
        let locationId: number = parseInt(locationIdString, 10);

        if (!isNaN(locationId)) {

          this.location = this.appData.getLocationById(locationId);
          if (this.location != null) {
            const locationKey = MonthEventFactory.getLocationKey(this.location);
            this.month = this.datetime.getCurrentMonth().value;
            this.monthplan = appData.getMonthplan().value;
            if (this.monthplan != null) {
              let events = this.monthplan.get(locationKey);
              this.events = [];

              events.forEach((event: IMonthEvent) => {
                console.log("event.dateDetails: " + event.dateDetails.dateobject)
                if (event.dateDetails.year == this.month.year
                  && event.dateDetails.month == this.month.monthNum) {
                  this.events.push(event);
                }
              })
              console.log(this.events.length + " printable Events loaded");
            }
            this.capacities = this.appData.getCapacities();
            this.showdata = true;
          }

        } else {
          alert("Fehler: Keine Location angegeben.");
        }
      }

    });
  }

  public isBooked(capacity: ICapacity) {
    for (let i = 0; i < this.events.length; i++) {
      let event:IMonthEvent = this.events[i];
      if(event.lead_capacity.id == capacity.id){
        return true;
      }else{
        let fields = event.fieldValues;
        for (let y = 0; y < fields.length; y++) {
          if(fields[y].value == capacity.id){
            return true;
          }
        };
      }
    };
    return false;
  }

  public getFieldValueById(event: IMonthEvent, fieldId: number) {
    let val = null;
    let fields = event.fieldValues;
    fields.forEach((f: IFieldDetails) => {
      if (f.id == fieldId) {
        val = f.valueResolved;
      }
    });
    return val;
  }

  private isCreator(event: IMonthEvent, capacity: ICapacity){
    return (event.lead_capacity.id == capacity.id);
  }

  private getFieldsByCapacity(event: IMonthEvent, capacity: ICapacity) {
    let fields: IFieldDetails[] = [];
    event.fieldValues.forEach((fv: IFieldDetails) => {
      if (fv.type == "CHOICE_CAPACITY" && fv.value == capacity.id) {
        fields.push(fv);
      }
    });
    return fields;
  }

  ngOnInit() {
  }

}
