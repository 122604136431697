import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthentificationService } from 'src/shared/authentification.service';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public shownWarning: boolean;
  public warningText: string;
  public title ="Willkommen";

  constructor(private service: AuthentificationService, private router:Router) { 
    this.service.getResponse().subscribe((response:IServerResponse<ISession>)=>{
      if(response != null){
        if(response.operationState){
          this.shownWarning = false;
          this.warningText = "";
        }else{
          this.shownWarning = true;
          this.warningText = response.message;
        }
      }else{
        this.shownWarning = true;
          this.warningText = "Bitte melden Sie sich an";
      } 
    })
  }

  public doPasswordReset(){
    this.router.navigate(["/passwordreset"]);
  }

  public doRegister(){
    this.router.navigate(["/register"]);
  }

  public doLogin(username: string, password: string) {
    this.service.authentificate(username, password,true);
  }

  ngOnInit() {
  }

}
