import { Injectable } from '@angular/core';
import { IServerResponse } from 'src/model/service/serverresponse';
import { AuthentificationService } from '../authentification.service';
import { GenericService } from './generic.service';
import { IEvent } from 'src/model/service/ievent';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EventService extends GenericService<IEvent[]>{


  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getevents", http, authService);
    this.onData().subscribe((data: IEvent[]) => {
      if (data != null) {
        console.log("EventService: Load of " + data.length + " Events successfully loaded.");
      }
    })
  }

  public update(event: IMonthEvent) {
    return this.http.post<IServerResponse<IEvent>>(this.baseUrl + "?action=updateEvent", event, this.httpOptions);
  }
  public delete(event: IMonthEvent) {
    return this.http.post<IServerResponse<IEvent>>(this.baseUrl + "?action=deleteEvent", event, this.httpOptions);
  }
  public post(event: IMonthEvent) {
    return this.http.post<IServerResponse<IEvent>>(this.baseUrl + "?action=createEvent", event, this.httpOptions);
  }

  public downloadICSFile(eventId: number) {

    let headers = new HttpHeaders({
      'Content-Type': 'text/calendar; charset=utf-8',
      'Accept': 'text/calendar',
      'Authorization': this.authtoken,
    })
    const options = { headers, responseType: 'blob' as 'blob' };

    this.http.get(this.baseUrl + "?action=downloadEvent&eventId=" + eventId, options).subscribe((data: any) => {
      
      var reader = new FileReader();
      var out = new Blob([data], { type: 'text/calendar' });
      reader.onload = function (e) {
        location.href = <string>reader.result;
      }
      reader.readAsDataURL(out);
    })
  }

  public getEventById(id: number): IEvent {
        let entry: IEvent = null;
        if(this.getEntries() != null) {

      this.getEntries().forEach((e: IEvent) => {
        if (e.id == id) {
          entry = e;
        }
      });
    }
    return entry;
  }

  public load(){
    //Load all Persons
    this.http.get<IServerResponse<IEvent[]>>(this.serviceUrl + "&ran=" + this.getRandomString(), this.httpOptions)
    .subscribe(
      (response) => {
        if (response != null) {
          let obj = JSON.parse(JSON.stringify(response));
          
          if(obj != null && obj.data != null && obj.data.length > 0){
            obj.data.forEach((o)=>{
              
              if(o.global_offerring == "true"){
                o.global_offerring = true;
              }else{
                o.global_offerring = false;
              }
            })
          }

          //Für alle  anderen booleans übernehmen
          let repsonseData: IServerResponse<IEvent[]> = obj;
          this.data.next(repsonseData.data);
        }

      },
      (error:HttpErrorResponse)=>{
          console.error("Error while aksing the service: " + error.status);
          this.authService.checkAuthentification();
      });
  }

}
