import { Injectable } from '@angular/core';
import { AuthentificationService } from './authentification.service';
import { CapacityService } from './modelservices/capacity.service';
import { EventService } from './modelservices/event.service';
import { LocationService } from './modelservices/location.service';
import { DivisionService } from './modelservices/division.service';
import { EventTypeService } from './modelservices/eventtype.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IApplicationData } from 'src/model/application/iappclicationdata';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { ICapacity } from 'src/model/service/icapacity';
import { IEvent } from 'src/model/service/ievent';
import { ILocation } from 'src/model/service/ilocation';
import { IDivision } from 'src/model/service/idivision';
import { IEventType } from 'src/model/service/ieventtype';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { MonthEventFactory } from 'src/model/application/factory/montheventfactory';
import { IDateDetails } from 'src/model/application/idatedetails';
import { DatetimeService } from './datetime.service';
import { IMonthSelection } from 'src/model/application/imonthselection';
import { FieldService } from './modelservices/field.service';
import { FieldValueService } from './modelservices/fieldvalue.service';
import { IField } from 'src/model/service/ifield';
import { IFieldValue } from 'src/model/service/ifieldvalue';
import { IFieldDetails } from 'src/model/application/ifielddetails';
import { OfferService } from './modelservices/offer.service';
import { IOffer } from 'src/model/service/ioffer';
import { OfferFactory } from 'src/model/application/factory/offerfactory';
import { IOfferDetails } from 'src/model/application/iofferdetails';
import { SettingService } from './modelservices/setting.service';
import { ISetting } from 'src/model/service/isettings';
import { ISettingDetail } from 'src/model/application/isettingdetail';
import { TextService } from './modelservices/text.service';
import { IText } from 'src/model/service/itext';
import { text } from '@angular/core/src/render3';
import { ICapacityCreation } from 'src/model/service/icapacitycreation';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {


  private subject: BehaviorSubject<IApplicationData> = new BehaviorSubject<IApplicationData>(null);
  private monthplan: BehaviorSubject<Map<string, IMonthEvent[]>> = new BehaviorSubject<Map<string, IMonthEvent[]>>(null);
  private loadingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private monthfactory: MonthEventFactory;
  private offerfactory: OfferFactory;
  private offers: BehaviorSubject<IOfferDetails[]> = new BehaviorSubject<IOfferDetails[]>(null);
  private selectedEvent: BehaviorSubject<IMonthEvent> = new BehaviorSubject<IMonthEvent>(null);
  private foundEvents: BehaviorSubject<IMonthEvent[]> = new BehaviorSubject<IMonthEvent[]>(null);
  private newEvent: IMonthEvent;
  private acceptedOffer: BehaviorSubject<IOfferDetails> = new BehaviorSubject<IOfferDetails>(null);
  private currentOffer: IOfferDetails = null;
  private noSettingsFound = false;
  private detectedDataChange = false;
  private data: IApplicationData = {
    session: null,
    events: null,
    locations: null,
    divisions: null,
    capacities: null,
    eventtypes: null,
    fields: null,
    values: null,
    offers: null,
    settings: null,
    texts: null
  };
  private inactiveCapacities: ICapacity[] = [];

  constructor(
    private authservice: AuthentificationService,
    private capaservice: CapacityService,
    private eventservice: EventService,
    private locationservice: LocationService,
    private divisionservice: DivisionService,
    private eventtypes: EventTypeService,
    private datetime: DatetimeService,
    private fieldservice: FieldService,
    private fieldvalueservice: FieldValueService,
    private offerservice: OfferService,
    private settingService: SettingService,
    private textService: TextService) {

    this.setupListeners();

    this.loadingState.next(true);

    this.subject.subscribe((d: IApplicationData) => {
      if (d != null) {
        if (d.capacities != null
          && d.divisions != null
          && d.events != null
          && d.eventtypes != null
          && d.locations != null
          && d.session != null
          && d.fields != null
          && d.values != null
          && d.offers != null
          && d.texts != null
          && (d.settings != null || this.noSettingsFound)
        ) {

          console.log("Application Data complete! Beginning processing of Data Model");

          let homedivision = this.getHomeDivision();
          if (homedivision != null) {
            this.monthfactory = new MonthEventFactory(this.data, this.datetime, homedivision.id);
            this.offerfactory = new OfferFactory(this.data, this.datetime);

            this.offers.next(this.offerfactory.getOffers());
            this.monthplan.next(this.monthfactory.getMonthplan());
            this.loadingState.next(false);

            if (this.currentOffer != null) {
              this.acceptedOffer.next(this.currentOffer);
              this.currentOffer = null;
            }
          } else {
            console.error("Could not target home division! You lose.");
          }

        }
      }
    })
  }

  public getMonthEventById(id: number): IMonthEvent {
    if (this.monthfactory != null) {
      return this.monthfactory.getMonthEventById(id);
    } else {
      return null;
    }

  }

  public isLoading(): BehaviorSubject<boolean> {
    return this.loadingState;
  }

  public getMonthplan(): BehaviorSubject<Map<string, IMonthEvent[]>> {
    return this.monthplan;
  }

  public getCapacityBId(id: string): ICapacity {
    return this.capaservice.getCapacityById(id);
  }

  public onData(): BehaviorSubject<IApplicationData> {
    return this.subject;
  }

  public getAllDates(): IDateDetails[] {
    return this.monthfactory.getAllDates();
  }

  private setupListeners() {
    this.authservice.getResponse().subscribe((res: IServerResponse<ISession>) => {
      if (res != null && res.operationState) {
        this.data.session = res.data;
        this.subject.next(this.data);
      }
    });

    this.capaservice.onData().subscribe((data: ICapacity[]) => {
      if (data != null) {

        this.inactiveCapacities = [];
        this.data.capacities = [];
        data.forEach((c: ICapacity) => {
          if (!c.active) {
            this.inactiveCapacities.push(c)
          } else {
            this.data.capacities.push(c);
          }
        })
        this.subject.next(this.data);
      }
    });

    this.eventservice.onData().subscribe((data: IEvent[]) => {
      if (data != null) {
        this.data.events = data;
        this.detectedDataChange = false;
        this.subject.next(this.data);
      }
    });

    this.locationservice.onData().subscribe((data: ILocation[]) => {
      if (data != null) {
        this.data.locations = data;
        this.subject.next(this.data);
      }
    });

    this.divisionservice.onData().subscribe((data: IDivision[]) => {
      if (data != null) {
        this.data.divisions = data;
        this.subject.next(this.data);
      }
    });

    this.eventtypes.onData().subscribe((data: IEventType[]) => {
      if (data != null) {
        this.data.eventtypes = data;
        this.subject.next(this.data);
      }
    });

    this.datetime.getCurrentMonth().subscribe((selection: IMonthSelection) => {
      if (selection != null && this.datetime.getSelectedMonthNum() != selection.monthNum) {
        let params: Map<string, string> = new Map<string, string>();
        params.set("month", "" + selection.monthNum);
        params.set("year", "" + selection.year);
        this.eventservice.reload(params);
      }
    });

    this.fieldservice.onData().subscribe((data: IField[]) => {
      if (data != null) {
        this.data.fields = data;
        this.subject.next(this.data);
      }
    });

    this.fieldvalueservice.onData().subscribe((data: IFieldValue[]) => {
      if (data != null) {
        this.data.values = data;
        this.subject.next(this.data);
      }
    });

    this.offerservice.onData().subscribe((data: IOffer[]) => {
      if (data != null) {
        this.data.offers = data;
        this.subject.next(this.data);
      }
    });

    this.settingService.onData().subscribe((data: ISetting[]) => {
      if (data != null) {
        this.data.settings = data;

        this.noSettingsFound = true;
        this.data.settings.forEach((s: ISetting) => {
          if (s.capacity_id == this.data.session.user) {
            console.log("No settings stored for user..");
            this.noSettingsFound = false;
          }
        })

        this.subject.next(this.data);
      }
    });

    this.textService.onData().subscribe((data: IText[]) => {
      if (data != null) {
        this.data.texts = data;
        this.subject.next(this.data);
      }
    });

    this.reload();
  }

  public reset() {
    console.warn("Destroying data.");
    this.data = {
      session: null,
      events: null,
      locations: null,
      divisions: null,
      capacities: null,
      eventtypes: null,
      fields: null,
      values: null,
      offers: null,
      settings: null,
      texts: null
    };
    this.loadingState.next(true);
    this.subject.next(this.data);
  }

  public reload() {


    console.warn("*******************************************************************************")
    console.warn("* WATCH YOUR PERFORMANCE!!! Factory is triggered everytime a request returns! *")
    console.warn("*******************************************************************************")

    this.capaservice.load();
    this.eventservice.load();
    this.locationservice.load();
    this.divisionservice.load();
    this.eventtypes.load();
    this.fieldservice.load();
    this.fieldvalueservice.load();
    this.offerservice.load();
    this.settingService.load();
    this.textService.load();
    //Array mit loadAll() ???
  }

  public getData(): IApplicationData {
    return this.data;
  }
  public getSelectedEvent(): BehaviorSubject<IMonthEvent> {
    return this.selectedEvent;
  }
  public getFoundEvents(): BehaviorSubject<IMonthEvent[]> {
    return this.foundEvents;
  }
  public selectEvent(event: IMonthEvent) {
    this.selectedEvent.next(event);
  }
  public selectFoundEvents(events: IMonthEvent[]) {
    this.foundEvents.next(events);
  }

  public getEventTypes(): IEventType[] {
    return this.data.eventtypes;
  }

  public getTimeOptions() {
    return this.datetime.getTimeoptions();
  }

  public getCapacitiesByLocation(location: ILocation) {
    let capas: ICapacity[] = [];
    this.data.capacities.forEach((c: ICapacity) => {
      if (c.homelocation == location.id) {
        capas.push(c);
      }
    });
    return capas;
  }

  public getCapacitiesByEvent(event: IMonthEvent): ICapacity[] {
    let capas = this.getCapacitiesByLocation(event.location);
    if (event.relatedOffer != null) {
      let offerCapacity = event.relatedOffer.create_capacity_id;
      let oc = this.getCapacityBId(offerCapacity);

      if (oc != null) {
        if (event.location.id != oc.homelocation) {
          capas.push(this.getCapacityBId(offerCapacity));
        }
      }
    }
    //Pürfen ob es sich um eine Unterstützungsanforderung handel
    let sessionCapacity = this.getSessionCapayity();
    if (event.type.id == MonthEventFactory.EDITABLE_EVENT_TYPE_ID && sessionCapacity.homelocation != event.location.id) {
      capas.push(sessionCapacity);
    }

    //prüfen ob der event-ersteller enthalten ist
    let found = false;
    capas.forEach((c: ICapacity) => {
      if (c.id == event.lead_capacity.id) {
        found = true;
      }
    });
    if (!found) {
      capas.push(event.lead_capacity);
    }

    //prüfen ob der prediger enthalten ist
    let sermonCapacity = null;
    event.fieldValues.forEach((fv) => {
      if (fv.id == FieldService.SERMON_FIELD_ID) { 
        sermonCapacity = fv.valueResolved;
        return;
      }
    });
    found = false;
    if(sermonCapacity) {
      capas.forEach((c: ICapacity) => {
        if (c.id == sermonCapacity.id) {
          found = true;
        }
      });
      if (!found) {
        capas.push(sermonCapacity);
      }
    }

    return capas;
  }

  public updateEvent(event: IMonthEvent) {
    this.detectedDataChange = true;
    return this.eventservice.update(event);
  }

  public initEventTypeChange(event: IMonthEvent): IFieldDetails[] {
    let fields = this.monthfactory.getFieldsByEventType(event.type);
    if (fields != null) {
      fields.forEach((fd: IFieldDetails) => {
        fd.event_id = event.id;
      });
    }

    return fields;
  }

  public deleteEvent(event: IMonthEvent) {
    this.detectedDataChange = true;
    return this.eventservice.delete(event);
  }
  public postEvent(event: IMonthEvent) {
    this.detectedDataChange = true;
    return this.eventservice.post(event);
  }

  public getNewEvent(): IMonthEvent {
    return this.newEvent;
  }

  public createNewEvent(date: IDateDetails, location: ILocation, relatedOffer?: IOfferDetails) {
    let sessionCapacity = this.getSessionCapayity();
    this.newEvent = this.monthfactory.createDummy(sessionCapacity, location, date, relatedOffer);
  }

  public getOffers(): IOfferDetails[] {
    return this.offerfactory.getOffers();
  }

  public getMyOffers() {
    let myoffers: IOfferDetails[] = [];
    let capacity = this.getSessionCapayity();
    let offers = this.getOffers();
    if (offers != null && offers.length > 0) {
      offers.forEach((o: IOfferDetails) => {

        let add = false;

        if (capacity.extended_range) {
          add = true;
        } else {
          if (o.capacity.id == capacity.id) {
            add = true;
          } else {
            if (o.location != null && o.location.id == capacity.homelocation) {
              add = true;
            } else if (o.location == null && this.isInMyDivision(o.capacity)) { //and user in my Home division
              add = true;
            } else if (o.capacity.homelocation == capacity.homelocation) {
              add = true;
            }
          }
        }

        if (add) {
          myoffers.push(o);
        }

      })
    }
    return myoffers;
  }

  private isInMyDivision(capacity: ICapacity) {

    let sessioncapacity = this.getSessionCapayity();
    let locations = this.getLocations(capacity);
    let found = false;
    locations.forEach((l: ILocation) => {
      if (l.id == sessioncapacity.homelocation) {
        found = true;
      }
    })
    return found;
  }


  public getLocationsByDivsion(divisionId: number) {
    let locations: ILocation[] = [];
    this.data.locations.forEach((l: ILocation) => {
      if (l.division == divisionId) {
        locations.push(l);
      }
    });
    return locations;
  }

  public getLocations(capacity?: ICapacity): ILocation[] {
    if (capacity != null) {

      if (capacity.extended_range) {
        return this.data.locations;
      } else {
        let homelocation = this.getLocationById(capacity.homelocation);
        let division = this.getDivisionById(homelocation.division);
        let locations: ILocation[] = this.getLocationsByDivsion(division.id);
        return locations;
      }

    } else {
      return this.data.locations;
    }
  }

  public getLocationByKey(locationKey: string) {

    let foundEntry: ILocation = null;
    if (!this.isLoading().value) {

      this.data.locations.forEach((l: ILocation) => {
        let key = MonthEventFactory.getLocationKey(l);
        if (key === locationKey) {
          foundEntry = l;
        }
      })
    }
    return foundEntry;
  }

  public getLocationById(id: number): ILocation {
    return this.locationservice.getLocationById(id);
  }

  public createOffer(offer: IOffer) {
    this.offerservice.createOffer(offer).subscribe((data) => {
      let response = <IServerResponse<IOffer>>JSON.parse(JSON.stringify(data));
      if (response.operationState) {
        this.acceptedOffer.next(null);
        this.reload();
      } else {
        alert("Fehler beim Anlegen: " + response.message)
      }

    });
  }

  public deleteOffer(offer: IOfferDetails) {
    this.offerservice.deleteOffer(offer).subscribe((data) => {
      let response = <IServerResponse<IOffer>>JSON.parse(JSON.stringify(data));
      if (response.operationState) {
        this.reload();
      } else {
        alert("Fehler beim Löschen: " + response.message)
      }

    });
  }

  public getEventsByOffer(offer: IOfferDetails) {

    if (offer == null) {
      return null;
    }

    console.log("Loading event matching offer " + JSON.stringify(offer));
    let locationEvents: IMonthEvent[] = [];

    //Angebot bezieht sich auf ein Datum und eine Gemeinde
    if (offer.date != null && offer.location != null) {
      console.log("Searching for event matching date and location");
      //get event by location and date
      let events = this.monthfactory.getMonthEventByDate(offer.date.dateobject, offer.location.id);
      if (events != null) {
        locationEvents = events;
      }
    } else {
      //Angebot bezieht nur auf ein bestimmtes Datum
      if (offer.date != null && offer.location == null) {
        console.log("Searching for event matching date");
        let events = this.monthfactory.getMonthEventByDate(offer.date.dateobject);
        if (events != null) {
          locationEvents = events;
        }
      } else if (offer.date == null && offer.location != null) {
        console.log("Searching for event matching location");
        //Angebot bezeiht sicht nur auf eine bestimmte Gemeinde
        let searchLocation = offer.location;
        if (offer.location == null) {
          let sessionCapacity = this.getCapacityBId(this.data.session.user);
          searchLocation = this.getLocationById(sessionCapacity.homelocation);
        }
        console.log("Loading all events of " + searchLocation.name);
        locationEvents = this.monthfactory.getLocationEvents(searchLocation);

      } else {
        console.log("Searching nothing xD");
      }
    }

    console.log("Found " + locationEvents.length + " Event(s)")
    return locationEvents;
  }

  public acceptOffer(offer: IOfferDetails) {
    //console.log("Selected offer: " + offer.id);

    let currentSelectedMonth: IMonthSelection = this.datetime.getCurrentMonth().value;
    let offerMonth: IMonthSelection = {
      monthNum: offer.date.month,
      monthName: offer.date.monthnameFull,
      year: offer.date.year
    }

    let currentMonthKey = currentSelectedMonth.monthNum + currentSelectedMonth.year;
    let offerMonthKey = offerMonth.monthNum + offerMonth.year;

    if (currentMonthKey != offerMonthKey) {
      this.datetime.selectDate(offer.date.dateobject);
    } else {
      this.eventservice.load();
    }
    this.currentOffer = offer;

  }
  public getAcceptedOffer(): Observable<IOfferDetails> {
    return this.acceptedOffer;
  }

  public getSettings(): ISettingDetail {

    if (this.data.settings != null) {

      let parsedSettings = null;
      this.data.settings.forEach((setting: ISetting) => {
        if (setting.capacity_id == this.getSessionCapayity().id) {
          parsedSettings = JSON.parse(setting.location_filter);
        }
      })

      if (parsedSettings != null) {
        return {
          capacity: this.getCapacityBId(this.getSessionCapayity().id),
          locationFilter: parsedSettings
        }
      } else {
        return null;
      }

    } else {
      return null;
    }
  }

  public getDivisionById(id: number) {
    let foundEntry: IDivision = null;
    this.data.divisions.forEach((division: IDivision) => {
      if (division.id == id) {
        foundEntry = division;
      }
    })
    return foundEntry;
  }

  public getSession(): ISession {
    if (this.data != null) {
      return this.data.session;
    } else {
      return null;
    }

  }

  public setSettings(settings: ISettingDetail) {
    return this.settingService.setSettings(settings);
  }

  public reloadSettings() {
    this.settingService.load();
  }



  public getHomeDivision(): IDivision {
    let division: IDivision = null;
    if (this.data != null) {
      let capa = this.getSessionCapayity();
      if (capa != null) {
        let location = this.getLocationById(capa.homelocation);
        if (location != null) {
          division = this.getDivisionById(location.division);
        }
      }
    }
    return division;
  }

  public getSessionCapayity() {
    if (this.data != null) {
      return this.getCapacityBId(this.data.session.user);
    } else {
      return null;
    }
  }

  public updateCapacity(capacity: ICapacity) {
    return this.capaservice.update(capacity);
  }

  public downloadEvent(id: number) {
    this.eventservice.downloadICSFile(id);
  }

  public static validateEmail(emailAdress: string) {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(emailAdress);
  }

  public dataChangeDetected() {
    return this.detectedDataChange;
  }

  public getTextByDate(date: Date) {
    let textstring = null;
    if (this.data.texts != null) {
      for (let i = 0; i < this.data.texts.length; i++) {
        let text = this.data.texts[i];
        let textdate = DatetimeService.parseDate(text.date);
        if (textdate.getFullYear() == date.getFullYear()
          && textdate.getMonth() == date.getMonth()
          && textdate.getDate() == date.getDate()) {
          textstring = text.text;
          break;
        }
      }
    } else {
      console.log("No texts found. Cannot offer a text");
    }

    if (textstring == null) {
      console.log("No valid text for date found: " + date);
    }

    return textstring;
  }
  public getInActiveCapacities(): ICapacity[] {
    return this.inactiveCapacities;
  }
  public reloadInActiveCapacities() {
    console.log("Reloading inactive capacities");
    this.isLoading().next(true);
    this.capaservice.reload(null);
  }
  public dislineCapacity(c: ICapacity) {
    this.detectedDataChange = true;
    return this.capaservice.disline(c);
  }
  public acceptCapacity(c: ICapacity) {
    this.detectedDataChange = true;
    return this.capaservice.accept(c);
  }
  public logout() {
    console.clear();
    this.reset();
    this.authservice.logout(true);
  }

  public getCapacities(): ICapacity[] {
    return this.data.capacities;
  }

  public createNewDummyCapacity(transportationObject: ICapacityCreation) {
    this.detectedDataChange = true;
    return this.capaservice.createDummyCapacity(transportationObject);
  }

  public isPartialPlannedServiceEvent(event: IMonthEvent) {
    const isServiceEventType = event.type.id == EventTypeService.EVENT_TYPE_ID_SERVICE;
    let sermonFound = false;
    if (event && isServiceEventType) {
      const resolvedEvent = this.monthfactory.getMonthEventById(event.id);
      if (resolvedEvent) {
        resolvedEvent.fieldValues.forEach((fv: IFieldDetails) => {
          if (fv.id == FieldService.SERMON_FIELD_ID && fv.value != null) {
            sermonFound = true;
          }
        });
      }
      return !sermonFound;
    } else {
      return false;
    }

  }

  ngOnInit() {

  }
}
