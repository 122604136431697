import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ISession } from 'src/model/service/isession';
import { ApplicationService } from 'src/shared/application.service';

@Component({
  selector: 'app-homescreen',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './homescreen.component.html',
  styleUrls: ['./homescreen.component.css']
})
export class HomescreenComponent implements OnInit {

  public timeout:string;
  public waitingTimeout:number;
  public isLoading:boolean = true;
  public showReconnectHint = false;
  matchingOffers: number = 0;

  constructor(private service: AuthentificationService, private appService:ApplicationService) {
    this.service.checkAuthentification();
    this.service.getSessionTimeout().subscribe((timer:string)=>{
      this.timeout = timer;
    });

    this.waitingTimeout = window.setTimeout(()=>{
      this.showReconnectHint = true;
    },3000);

    if(appService.dataChangeDetected()){
      console.log("Change of data was detected: Reloading...")
      this.appService.reload();
    }
    
    this.appService.isLoading().subscribe((state:boolean)=>{
      this.isLoading = state;
    });
  }

  public logout(){
    this.appService.logout();
  }
  public reload() {
    clearTimeout(this.waitingTimeout);
    location.reload();
  }

  ngOnInit() {
  }

}
