import { Injectable } from '@angular/core';
import { ILocation } from 'src/model/service/ilocation';
import { GenericService } from './generic.service';
import { AuthentificationService } from '../authentification.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends GenericService<ILocation[]> {

  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getlocations", http, authService);
    this.onData().subscribe((data: ILocation[]) => {
      if (data != null) {
        console.log("LocationService: Load of " + data.length + " Locations successfully loaded.");
      }
    })
  }
  public getLocationById(id:number):ILocation{
    let entry:ILocation = null;
    if(this.getEntries() != null){

      this.getEntries().forEach((e:ILocation)=>{
        if(e.id == id){
          entry = e;
        }
      });
    }
    return entry;
  }
}
