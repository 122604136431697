import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router, ActivationEnd } from '@angular/router';
import { AuthentificationService } from 'src/shared/authentification.service';
import { ApplicationService } from 'src/shared/application.service';
import { IApplicationData } from 'src/model/application/iappclicationdata';
import { IOfferDetails } from 'src/model/application/iofferdetails';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  @Input() title: string = "GEMEINDEPLANER";
  @Input() hasBackLink: boolean = false;
  @Input() badgetitle: string;

  constructor(private router: Router, private appdate:ApplicationService) {
    this.appdate.isLoading().subscribe((state:boolean)=>{
      if(!state){
        let offers = this.appdate.getMyOffers();
        if(offers != null){
          if(offers.length == 1){
            this.badgetitle = "Ein Angebot";
          }else{
            if(offers.length > 1){
              this.badgetitle = offers.length + " Angebote";
            }else{
              this.badgetitle = null;
            }
          }
          
        }else{
          this.badgetitle = null;
        }
      }
    })
  }

  public getHome() {
    this.router.navigate(["/home"]);
  }

  public offers() {
    this.router.navigate(["/transactions"]);
  }

  ngOnInit() {
  }


}
