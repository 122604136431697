import { Component, OnInit } from '@angular/core';
import { IMonthEvent } from 'src/model/application/imonthevent';
import { ApplicationService } from 'src/shared/application.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-eventselectionmodal',
  templateUrl: './eventselectionmodal.component.html',
  styleUrls: ['./eventselectionmodal.component.css']
})
export class EventselectionmodalComponent implements OnInit {

  public events:IMonthEvent[];
  public selectedEvent:IMonthEvent;

  constructor(private appdata: ApplicationService,private router:Router) { 
    this.appdata.getFoundEvents().subscribe((events:IMonthEvent[])=>{
      if(events != null){
        this.events = events;
        this.selectedEvent = events[0];
      }
    })
  }

  ngOnInit() {
  }

}
