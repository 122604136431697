import { Component, OnInit } from "@angular/core";
import { ApplicationService } from "src/shared/application.service";
import { IMonthEvent } from "src/model/application/imonthevent";
import { IDateDetails } from "src/model/application/idatedetails";
import { IMonthSelection } from "src/model/application/imonthselection";
import { DatetimeService } from "src/shared/datetime.service";
import { Router } from "@angular/router";
import { DatepickerOptions } from "ng2-datepicker";
import { ISettingDetail } from "src/model/application/isettingdetail";
import { ICapacity } from "src/model/service/icapacity";
import { MonthEventFactory } from "src/model/application/factory/montheventfactory";
import { IFieldDetails } from 'src/model/application/ifielddetails';
declare var $: any;
@Component({
  selector: "app-monthplan",
  templateUrl: "./monthplan.component.html",
  styleUrls: ["./monthplan.component.css"]
})
export class MonthplanComponent implements OnInit {
  
  public monthplan: Map<string, IMonthEvent[]>;
  public allDates: IDateDetails[];
  public selectedEvent: IMonthEvent = null;
  public foundDates: IMonthEvent[] = null;
  public month: IMonthSelection;
  public selectDate = false;
  public settings: ISettingDetail;
  public hiddenLocations: number = 0;
  public inactiveCapacities: number = 0;
  public sessionUserCapacity: ICapacity;
  public showMonthplan = true;
  private myEvents: IMonthEvent[] = [];

  constructor(
    private appdata: ApplicationService,
    private datetime: DatetimeService,
    private router: Router
  ) {
    this.appdata.isLoading().subscribe((state: boolean) => {

      if (!state) {
        let iac = this.appdata.getInActiveCapacities();
        if (iac != null) {
          this.inactiveCapacities = iac.length;
        }


        this.sessionUserCapacity = this.appdata.getSessionCapayity();

        this.settings = this.appdata.getSettings();
        if (this.settings == null) {
          this.settings = {
            capacity: this.sessionUserCapacity,
            locationFilter: {
              locations: []
            }
          };
          this.hiddenLocations = 0;
        } else {
          this.hiddenLocations =
            this.appdata.getLocations(this.sessionUserCapacity).length -
            this.settings.locationFilter.locations.length;
        }

        //Monatsplanung holen
        appdata.getMonthplan().subscribe((data: Map<string, IMonthEvent[]>) => {
          let filteredMonthplan = new Map<string, IMonthEvent[]>();
          let filteredAlldates: IDateDetails[] = [];
          this.myEvents = [];
          if (data != null) {
            data.forEach((value: IMonthEvent[], key: string) => {
              if (this.sessionUserCapacity != null) {

                value.forEach((e: IMonthEvent) => {
                  if(e.lead_capacity != null){
                    if (e.lead_capacity.id == this.sessionUserCapacity.id) {
                      this.addToMyEvents(e);
                    } else {
                      e.fieldValues.forEach((fv: IFieldDetails) => {
                        if (fv.value == this.sessionUserCapacity.id) {
                          this.addToMyEvents(e);
                        }
                      })
                    }
                  }
                });
              }

              if (this.filter(key)) {
                filteredMonthplan.set(key, value);
                value.forEach((e: IMonthEvent) => {
                  filteredAlldates.push(e.dateDetails);
                });
              }
            });
            this.monthplan = filteredMonthplan;
            this.allDates = MonthEventFactory.distinctDateList(
              filteredAlldates
            );

            this.allDates.sort((a: IDateDetails, b: IDateDetails) => {
              return a.dateobject.getTime() - b.dateobject.getTime();
            });

          }

        });

        //Datumsseletion holen
        this.datetime
          .getCurrentMonth()
          .subscribe((selectedMonth: IMonthSelection) => {
            if (selectedMonth != null) {
              this.month = selectedMonth;
            }
          });

      }

    });
  }

  public addToMyEvents(e: IMonthEvent) {
    
    let found = false;
    for(let i=0; i<this.myEvents.length; i++){
      if(this.myEvents[i].id === e.id){
        found = true;
        break;
      }
    }

    if(!found){
      this.myEvents.push(e);
    }
  }

  public filterMonthPlan(
    data: Map<string, IMonthEvent[]>,
    setting: ISettingDetail
  ) { }

  public loadSettings() {
    this.router.navigate(["/settings"]);
  }

  public filter(locationKey: string) {

    let state = false;
    if (this.settings != null && this.settings.locationFilter != null) {
      let filterLocations = this.settings.locationFilter.locations;

      if (filterLocations.length > 0) {
        let location = this.appdata.getLocationByKey(locationKey);

        for (let i = 0; i < filterLocations.length; i++) {
          //console.log("Zeige an: " + filterLocations[i]);
          if (filterLocations[i] === location.id) {
            return true;
          }
        }
        return false;

      } else {
        //Filter nicht gesetzt oder nicht vorhanden: zeige an
        return true;
      }
    } else {
      //Filter nicht vorhanden: zeige an
      return true;
    }
  }

  private getEventByDate(
    locationname: string,
    searchdate: IDateDetails
  ): IMonthEvent[] {
    let allEvents: IMonthEvent[] = this.monthplan.get(locationname);
    let result: IMonthEvent[] = [];

    for (let i = 0; i < allEvents.length; i++) {
      let event: IMonthEvent = allEvents[i];
      if (event.dateDetails.date == searchdate.date) {
        result.push(event);
      }
    }
    return result;
  }

  private getEventStatus(
    locationname: string,
    searchdate: IDateDetails
  ): number {
    let event: IMonthEvent[] = this.getEventByDate(locationname, searchdate);
    if (event != null) {

      if (event.length == 0) {
        return 0; // nothing planned
      } else {

        if (event.length == 1) {
          if (parseInt(event[0].type.id + "", 10) === MonthEventFactory.EDITABLE_EVENT_TYPE_ID) {
            return 3; // support needed
          } else {
            if (event[0].global_offerring) {
              return 2; // global booked
            } else {
              if(this.appdata.isPartialPlannedServiceEvent(event[0])) {
                return 6;
              }else{
                return 1; // booked
              }
            }
          }
        } else {
          if (event.length > 1) {
            for (let i = 0; i < event.length; i++) {
              if (parseInt(event[i].type.id + "", 10) === MonthEventFactory.EDITABLE_EVENT_TYPE_ID) {
                return 5; // multiple entries. at least one with support needed
              }
            }
            return 4; // multiple entries.
          }
        }


      }


    } else {
      return 0;
    }
  }

  private isHomeLocation(locationKey: string) {
    let sessionCapacity = this.appdata.getSessionCapayity();
    if (sessionCapacity != null) {
      let sessionLocationId = sessionCapacity.homelocation;
      let location = this.appdata.getLocationByKey(locationKey);

      if (location != null && location.id == sessionLocationId) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

  }

  private print(locationKey: string) {
    let location = this.appdata.getLocationByKey(locationKey);
    this.router.navigate(["/print/" + location.id]);
  }

  public capacityManagement() {
    this.router.navigate(["/management"]);
  }

  public help() {
    this.router.navigate(["/help"]);
  }

  public reload() {
    location.reload();
  }

  private select(events: IMonthEvent[]) {
    this.selectedEvent = events[0];
    //this.appdata.selectEvent(events[0]);
    this.appdata.selectFoundEvents(events);
    $("#information").modal("show");
  }

  private multiSelect(events: IMonthEvent[]) {
    if (events.length > 0) {
      this.foundDates = events;
      this.appdata.selectFoundEvents(events);
      $("#multieventselection").modal("show");
    } else {
      console.log("No Elements found.")
    }
  }

  private create(locationKey: string, date: IDateDetails) {
    let sessionCapacity = this.appdata.getSessionCapayity();
    if (this.isHomeLocation(locationKey) || sessionCapacity.extended_range) {
      if (
        confirm(
          "Das Datum " +
          date.daynameFull +
          ", " +
          date.day +
          ". " +
          date.monthnameFull +
          " ist noch nichtbelegt.\nNeue Veranstaltung jetzt erstellen?"
        )
      ) {
        let location = this.appdata.getLocationByKey(locationKey);
        this.appdata.createNewEvent(date, location);
        this.router.navigate(["/edit/new/"]);
      }
    }
  }

  public datePicker() {
    this.selectDate = true;
    $("#datepicker").modal("show");
  }

  public offers() {
    this.router.navigate(["/transactions"]);
  }

  public showGrid(state: boolean) {
    this.showMonthplan = state;
  }

  ngOnInit() { }
}
