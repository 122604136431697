import { Injectable } from '@angular/core';
import { ICapacity } from 'src/model/service/icapacity';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { AuthentificationService } from '../authentification.service';
import { GenericService } from './generic.service';
import { IEvent } from 'src/model/service/ievent';
import { IField } from 'src/model/service/ifield';
import { IFieldValue } from 'src/model/service/ifieldvalue';

@Injectable({
  providedIn: 'root'
})
export class FieldValueService extends GenericService<IFieldValue[]>{
  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getfieldvalues", http, authService);
    this.onData().subscribe((data: IFieldValue[]) => {
      if (data != null) {
        console.log("FieldValueService: Load of " + data.length + " Field-Values successful.");
      }
    })
  }
}
