import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/shared/application.service';
import { ICapacity } from 'src/model/service/icapacity';
import { MonthplanComponent } from '../monthplan/monthplan.component';
import { MonthEventFactory } from 'src/model/application/factory/montheventfactory';
import { ILocation } from 'src/model/service/ilocation';


@Component({
  selector: 'app-helpmodal',
  templateUrl: './helpmodal.component.html',
  styleUrls: ['./helpmodal.component.css']
})
export class HelpmodalComponent implements OnInit {
  public user: ICapacity;
  public location:ILocation;
  public locationKey:string;
  public title: string = "Hilfe";

  constructor(private appdata: ApplicationService) {
    this.appdata.isLoading().subscribe((state: boolean) => {
      if (!state) {
        this.user = this.appdata.getSessionCapayity();
        this.location = this.appdata.getLocationById(this.user.homelocation);
        this.locationKey = MonthEventFactory.getLocationKey(this.location);
      }
    });
  }

  ngOnInit() {
  }

}
