import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomescreenComponent } from './homescreen/homescreen.component';
import { EditorComponent } from './editor/editor.component';
import { RegisterComponent } from './register/register.component';
import { TransactionsComponent } from './transactions/transactions.component';
import { PrintviewComponent } from './printview/printview.component';
import { SettingService } from 'src/shared/modelservices/setting.service';
import { SettingsComponent } from './settings/settings.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import { CapacityManagementComponent } from './capacitymanagement/capacitymanagement.component';
import { HelpmodalComponent } from './helpmodal/helpmodal.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomescreenComponent },
  { path: 'edit/:id', component: EditorComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'transactions', component: TransactionsComponent },
  { path: 'print/:location', component: PrintviewComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'passwordreset', component: PasswordresetComponent },
  { path: 'management', component: CapacityManagementComponent },
  { path: 'help', component: HelpmodalComponent}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
