import { IApplicationData } from '../iappclicationdata';
import { DatetimeService } from 'src/shared/datetime.service';
import { IEventType } from 'src/model/service/ieventtype';
import { ICapacity } from 'src/model/service/icapacity';
import { ILocation } from 'src/model/service/ilocation';

export abstract class BaseFactory {
    constructor(protected appData: IApplicationData, protected dateserver: DatetimeService) {
        
    }

    protected abstract process() ;

    protected getTypeById(id: number): IEventType {
        let foundEntry: IEventType;
        this.appData.eventtypes.forEach((type: IEventType) => {
            if (type.id == id) {
                foundEntry = type;
            }
        });
        return foundEntry;
    }

    protected getCapacityById(id: string): ICapacity {
        let data: ICapacity;
        this.appData.capacities.forEach((e: ICapacity) => {
            if (e.id == id) {
                data = e;
            }
        });
        return data;
    }

    protected getLocationById(id: number): ILocation {
        let data: ILocation;
        this.appData.locations.forEach((l: ILocation) => {
            if (l.id == id) {
                data = l;
            }
        });
        return data;
    }
}