import { Injectable } from '@angular/core';
import { ICapacity } from 'src/model/service/icapacity';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { AuthentificationService } from '../authentification.service';
import { GenericService } from './generic.service';
import { ICapacityCreation } from 'src/model/service/icapacitycreation';

@Injectable({
  providedIn: 'root'
})
export class CapacityService extends GenericService<ICapacity[]>{
  
  
  
  constructor( http: HttpClient, authService: AuthentificationService) {
    super("?action=getcapacities",http,authService);
    this.onData().subscribe((data: ICapacity[]) => {
      if (data != null) {
        console.log("CapacityService: Load of " + data.length + " Capacities successfully loaded.");
      }
    })
  }

  public load(){
    //Load all Persons
    this.http.get<IServerResponse<ICapacity[]>>(this.defaultServiceUrl + "&ran=" + this.getRandomString(), this.httpOptions)
    .subscribe(
      (response) => {
        if (response != null) {
          let obj = JSON.parse(JSON.stringify(response));
          
          if(obj != null && obj.data != null && obj.data.length > 0){
            obj.data.forEach((o)=>{
              if(o.extended_range == "true"){
                o.extended_range = true;
              }else{
                o.extended_range = false;
              }
              if(o.active == "true"){
                o.active = true;
              }else{
                o.active = false;
              }
              if(o.leadingposition == "1"){
                o.leadingposition = true;
              }else{
                o.leadingposition = false;
              }
            })
          }

          //Für alle  anderen booleans übernehmen
          let repsonseData: IServerResponse<ICapacity[]> = obj;
          this.data.next(repsonseData.data);
        }

      },
      (error:HttpErrorResponse)=>{
          console.error("Error while aksing the service: " + error.status);
          this.authService.checkAuthentification();
      });
  }

  
  public createDummyCapacity(capacity: ICapacityCreation) {
    this.serviceUrl = this.baseUrl + "?action=createDummyCapacitiy";
    return this.http.post<IServerResponse<ICapacity>>(this.serviceUrl, capacity, this.httpOptions);
  }

  public createCapacity(capacity: ICapacityCreation) {
    this.serviceUrl = this.baseUrl + "?action=createCapacitiy";
    return this.http.post<IServerResponse<ICapacity>>(this.serviceUrl, capacity, this.httpOptions);
  }
  
  public update(capacity: ICapacity) {
    this.serviceUrl = this.baseUrl + "?action=updateCapacitiy";
    return this.http.post<IServerResponse<ICapacity>>(this.serviceUrl, capacity, this.httpOptions);
  }

  public disline(capacity: ICapacity){
    this.serviceUrl = this.baseUrl + "?action=dislinecapacity";
    return this.http.post<IServerResponse<ICapacity>>(this.serviceUrl, capacity, this.httpOptions);
  }

  public accept(capacity: ICapacity){
    this.serviceUrl = this.baseUrl + "?action=acceptcapacity";
    return this.http.post<IServerResponse<ICapacity>>(this.serviceUrl, capacity, this.httpOptions);
  }

  public getCapacityById(id:string):ICapacity{
    let entry:ICapacity = null;
    if(this.getEntries() != null){

      this.getEntries().forEach((c:ICapacity)=>{
        if(c.id == id){
          entry = c;
        }
      });
    }
    return entry;
  }
}
