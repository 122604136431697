import { Injectable } from '@angular/core';
import { IMonthSelection } from 'src/model/application/imonthselection';
import { IDateDetails } from 'src/model/application/idatedetails';
import { BehaviorSubject } from 'rxjs';
import { IDateTimename } from 'src/model/application/idatetimename';
import { DatepickerOptions } from 'ng2-datepicker';

import * as deLocal from 'date-fns/locale/de';

@Injectable({
  providedIn: 'root'
})
export class DatetimeService {

  private monthTable: IDateTimename[];
  private dayTable: IDateTimename[];
  public static readonly MAX_DATE_SELECTION_FUTURE = 12;
  private selectedDate: Date = new Date();
  private currentMonth: BehaviorSubject<IMonthSelection> = new BehaviorSubject<IMonthSelection>(null);
  private datepickerOptions:DatepickerOptions;
  
  constructor() {
    this.initDayTable();
    this.initMonthTable();
    this.update();
    this.datepickerOptions = {
      minYear: 2019,
      maxYear: 2030,
      displayFormat: 'dd, DD.MM.YYYY',
      addClass: 'bottomCorrection',
      locale: deLocal
    };
  }

  private initMonthTable() {
    this.monthTable = [];
    this.monthTable[1] = { name: "Januar", shortname: "JAN" };
    this.monthTable[2] = { name: "Februar", shortname: "FEB" };
    this.monthTable[3] = { name: "März", shortname: "MRZ" };
    this.monthTable[4] = { name: "April", shortname: "APR" };
    this.monthTable[5] = { name: "Mai", shortname: "MAI" };
    this.monthTable[6] = { name: "Juni", shortname: "JUN" };
    this.monthTable[7] = { name: "Juli", shortname: "JUL" };
    this.monthTable[8] = { name: "August", shortname: "AUG" };
    this.monthTable[9] = { name: "September", shortname: "SEP" };
    this.monthTable[10] = { name: "Oktober", shortname: "OKT" };
    this.monthTable[11] = { name: "November", shortname: "NOV" };
    this.monthTable[12] = { name: "Dezember", shortname: "DEZ" };
  }


  private initDayTable() {
    this.dayTable = [];
    this.dayTable[0] = { name: "Sonntag", shortname: "SO" };
    this.dayTable[1] = { name: "Montag", shortname: "MO" };
    this.dayTable[2] = { name: "Dienstag", shortname: "DI" };
    this.dayTable[3] = { name: "Mittwoch", shortname: "MI" };
    this.dayTable[4] = { name: "Donnerstag", shortname: "DO" };
    this.dayTable[5] = { name: "Freitag", shortname: "FR" };
    this.dayTable[6] = { name: "Samstag", shortname: "SA" };
  }

  public createDateDetails(datestring?: string, date?: Date): IDateDetails {


    let d: Date;
    if (datestring != null) {
      d = new Date(datestring);
    } else {
      if (date != null) {
        d = date
      } else {
        d = new Date();
      }
      datestring = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    }
    


    //2019-05-05
    let monthIndex = d.getMonth() + 1;
    let details: IDateDetails = {
      date: datestring,
      daynameFull: this.dayTable[d.getDay()].name,
      daynameShort: this.dayTable[d.getDay()].shortname,
      month: monthIndex,
      monthnameFull: this.monthTable[monthIndex].name,
      monthnameShort: this.monthTable[monthIndex].shortname,
      year: d.getFullYear(),
      day: d.getDate(),
      dateobject: d
    }
    return details;

  }

  private update() {
    let monthIndex = this.selectedDate.getMonth() + 1; 
    this.currentMonth.next({
      monthNum: monthIndex,
      monthName: this.monthTable[monthIndex].name,
      year: this.selectedDate.getFullYear()
    });
  }

  public getSelectedDate(): Date {
    return this.selectedDate;
  }

  public getSelectedMonthNum(): number {
    return this.selectedDate.getMonth();
  }

  public getCurrentMonth(): BehaviorSubject<IMonthSelection> {
    return this.currentMonth;
  }

  public next() {
    let d = this.selectedDate;
    d.setMonth(d.getMonth() + 1);
    this.selectedDate = d;
    this.update();
  }

  public previous() {
    let d = this.selectedDate;
    d.setMonth(d.getMonth() - 1);
    this.selectedDate = d;
    this.update();
  }

  public selectDate(d:Date){
    this.selectedDate = d;
    this.update();
  }

  public getTimeoptions() {
    const quarterHours = ["00", "15", "30", "45"];

    let times = [];
    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 4; j++) {
        times.push(('0' + i).slice(-2) + ":" + quarterHours[j]);
      }
    }
    return times;
  }

  public static parseDate(datetimeString: string): Date {

    //console.group("parseDate");
    //console.log("Parsing: " + datetimeString);

    if (datetimeString != null) {

      let d = new Date();
      let parts = datetimeString.split(" ");
      let dateParts = parts[0];
      let timeParts = parts[1];

      if (dateParts != null) {
        //console.log("Setting Dateinformation: " + datetimeString);
        let datePartsSplit = dateParts.split("-");
        let year = parseInt(datePartsSplit[0], 10);
        let month = parseInt(datePartsSplit[1], 10) - 1;
        let day = parseInt(datePartsSplit[2], 10);
        d.setFullYear(year); //console.log("Setting year: " + year);
        d.setMonth(month); //console.log("Setting month: " + month);
        d.setDate(day); //console.log("Setting day: " + day);

      }
      if (timeParts != null) {
        //console.log("Setting Timeinformation: " + datetimeString);
        let timePartsSplit = timeParts.split(":");
        let hours = parseInt(timePartsSplit[0], 10);
        let minutes = parseInt(timePartsSplit[1], 10);
        let seconds = parseInt(timePartsSplit[2], 10);
        d.setHours(hours); //console.log("Setting hours: " + hours);
        d.setMinutes(minutes); //console.log("Setting minutes: " + minutes);
        d.setSeconds(seconds); //console.log("Setting seconds: " + seconds);
      }
      //console.groupEnd();
      return d;
    } else {
      return null;
    }


  }

  public getDatepickerOptions(): DatepickerOptions{
    return this.datepickerOptions;
  }
}
