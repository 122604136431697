import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';
import { IOffer } from 'src/model/service/ioffer';
import { AuthentificationService } from '../authentification.service';
import { HttpClient } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { IOfferDetails } from 'src/model/application/iofferdetails';
import { ISetting } from 'src/model/service/isettings';
import { ILocationFilter } from 'src/model/application/factory/ilocationfilter';
import { ISettingDetail } from 'src/model/application/isettingdetail';
import { IText } from 'src/model/service/itext';

@Injectable({
  providedIn: 'root'
})
export class TextService extends GenericService<IText[]>{
  
  

  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getTexts", http, authService);
    this.onData().subscribe((data: IText[]) => {
      if (data != null) {
        console.log("SettingService: Load of " + data.length + " Texts successfully.");
      }
    })
  }
  
}
