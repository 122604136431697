import { Injectable } from '@angular/core';
import { ICapacity } from 'src/model/service/icapacity';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IServerResponse } from 'src/model/service/serverresponse';
import { ISession } from 'src/model/service/isession';
import { AuthentificationService } from '../authentification.service';
import { GenericService } from './generic.service';
import { IEvent } from 'src/model/service/ievent';
import { IField } from 'src/model/service/ifield';

@Injectable({
  providedIn: 'root'
})
export class FieldService extends GenericService<IField[]>{
  public static readonly SERMON_FIELD_ID = 5;
  constructor(http: HttpClient, authService: AuthentificationService) {
    super("?action=getfields", http, authService);
    this.onData().subscribe((data: IField[]) => {
      if (data != null) {
        console.log("FieldService: Load of " + data.length + " Fields successful.");
      }
    })
  }
}
